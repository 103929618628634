import * as React from "react";
import {
    Stack,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
} from "../../sharedComponent";
import { styled } from "@mui/material/styles";

const InputError = styled("span")(() => ({
    color: "red",
}));
const MultipleCheckboxes = ({ value, onChange, openDay, error }: any) => {
    const [checkValue, setCheckValue] = React.useState(
        value ? value?.split(",") : ["", "", "", "", "", "", ""]
    );

    const handleCheckboxChange = (e, index) => {
        checkValue[index] = e.target.checked ? "1" : "";
        onChange({ target: { value: checkValue.join(",") } });
        setCheckValue([...checkValue]);
    };

    return (
        <Stack direction={"row"} flexWrap={"wrap"}>
            {openDay &&
                openDay.map((x: any, index) => (
                    <FormControlLabel
                        key={`${x.label}${index}`}
                        control={
                            <Checkbox
                                defaultChecked
                                name={x.label}
                                checked={checkValue[index]}
                                onChange={(e) => handleCheckboxChange(e, index)}
                            />
                        }
                        label={x.label}
                    />
                ))}
            <Grid lg={12} pl={0} style={{ textAlign: "left" }}>
                {error && <InputError>{error}</InputError>}
            </Grid>
        </Stack>
    );
};

export default MultipleCheckboxes;
