import React from "react";
import {
    Box,
    Card,
    Typography,
    IconButton,
    ExportExcel,
} from "../../sharedComponent";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import TableData from "../../sharedComponent/dynamicTable";
import TabsComponent from "../../sharedComponent/dynamicTabs";
import useCall, { useWaitCall } from "../../../hooks/useCall";
import {
    getAccounts,
    generateLink,
    getAccountStats,
    sentToBCOS,
} from "../../../services/AccountApi";
import SpinnerWrapper from "../../sharedComponent/spinWrapper";
import { createLeadColumn, exportColumnList } from "../../../utils/apiData";
import useTrans from "../../../hooks/useTranslation";
import { AlertContext } from "../../../context/alterContext";
import { ReactComponent as LinkIcon } from "../../../assets/link.svg";
import { ReactComponent as PlusIcon } from "../../../assets/plus-border.svg";
import { ReactComponent as ShareIcon } from "../../../assets/share-icon.svg";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_STATUS_MAPPING } from "../../../constants/account.status";
import { IAccountFilters } from "../../../interfaces/account";
import BasicModal from "../../sharedComponent/modal";
import { CreateLink } from "./createLink";
import { useCommonContext } from "@betagro/ui-common";
import { ROLES } from "../../../constants/role.status";

const CreateAccount = () => {
    const [selectedLabel, setSelectedLabel] = React.useState(0);
    const [message] = useTrans();
    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);
    const [refresh, setRefresh] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [showOtpModel, setShowOtpModel] = React.useState(false);
    const [leadId, setLeadId] = React.useState(null);
    const _exporter = React.createRef<ExcelExport>();
    const [exportList, setExportList] = React.useState([]);
    const [downloadList, setDownloadList] = React.useState([]);
    const { userContextInfo } = useCommonContext();

    const handlePageChange = (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: any
    ) => {
        setPage(newPage);
    };

    const handleRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
    };

    const tabLabels = [
        {
            name: message("createAccount.tab.all.name"),
            header: message("createAccount.tab.all.header"),
            count: 0,
        },
        {
            name: message("createAccount.tab.new.name"),
            header: message("createAccount.tab.new.header"),
            count: 0,
        },
        {
            name: message("createAccount.tab.progress.name"),
            header: message("createAccount.tab.progress.header"),
            count: 0,
        },
        {
            name: message("createAccount.tab.approved.name"),
            header: message("createAccount.tab.approved.header"),
            count: 0,
        },
        {
            name: message("createAccount.tab.sendToLead.name"),
            header: message("createAccount.tab.sendToLead.header"),
            count: 0,
        },
        {
            name: message("createAccount.tab.completed.name"),
            header: message("createAccount.tab.completed.header"),
            count: 0,
        },
        {
            name: message("createAccount.tab.reject.name"),
            header: message("createAccount.tab.reject.header"),
            count: 0,
        },
    ];

    const styleLink = {
        width: "20px",
        height: "16px",
        fontFamily: "'Font Awesome 6 Pro'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#47474A",
    };
    const handleLabelChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        newValue: number
    ) => {
        setSelectedLabel(newValue);
        setPage(1);
    };

    const getFilter = (page, rowsPerPage) => {
        const filters: IAccountFilters = {
            page,
            pageSize: rowsPerPage,
        };

        if (selectedLabel) {
            switch (selectedLabel) {
                case 1:
                    filters.status = [ACCOUNT_STATUS_MAPPING.NEW];
                    break;
                case 2:
                    filters.status = [
                        ACCOUNT_STATUS_MAPPING.IN_PROGRESS,
                        ACCOUNT_STATUS_MAPPING.PENDING_FOR_APPROVAL,
                    ];
                    break;
                case 3:
                    filters.status = [ACCOUNT_STATUS_MAPPING.APPROVED];
                    break;
                case 4:
                    filters.status = [
                        ACCOUNT_STATUS_MAPPING.SEND_TO_LEAD,
                        ACCOUNT_STATUS_MAPPING.CUSTOMER_LINK_SENT,
                        ACCOUNT_STATUS_MAPPING.CUSTOMER_FORM_SUBMITTED,
                    ];
                    break;
                case 5:
                    filters.status = [
                        ACCOUNT_STATUS_MAPPING.COMPLETED,
                        ACCOUNT_STATUS_MAPPING.SEND_TO_BCOS,
                    ];
                    break;
                default:
                    filters.status = [ACCOUNT_STATUS_MAPPING.REJECTED];
                    break;
            }
        }
        return filters;
    };

    const accountApi = useCall(
        () => {
            const filters = getFilter(page, rowsPerPage);
            return getAccounts(filters);
        },
        [selectedLabel, page, rowsPerPage, refresh],
        []
    );

    const fetchExpoerData = useWaitCall(
        (count) => {
            const filters = getFilter(1, count);
            return getAccounts(filters);
        },
        {},
        async (response) => {
            await setDownloadList(response?.data?.data?.data || []);
        },
        (error) => {
            alert.setError(
                error?.data?.errors?.join(",") || "Something went wrong"
            );
        }
    );

    React.useEffect(() => {
        if (downloadList?.length && _exporter.current) {
            _exporter.current.save(downloadList);
        }
    }, [downloadList]);

    const handleExport = async () => {
        if (exportList.length) {
            const list = accountApi.data?.data?.data
                ? accountApi.data?.data?.data.filter(
                      (val) => exportList.indexOf(val.id) >= 0
                  )
                : [];
            await setDownloadList(list);
        } else if (accountApi.data?.data?.count) {
            fetchExpoerData.callback(accountApi.data?.data?.count);
        }
    };

    const accountStatsApi = useCall(
        () => {
            return getAccountStats();
        },
        [refresh],
        []
    );

    if (
        accountStatsApi?.data?.data?.success &&
        accountStatsApi?.data?.data?.data?.[0]
    ) {
        tabLabels[0].count = accountStatsApi?.data?.data?.data?.[0]?.All || 0;
        tabLabels[1].count = accountStatsApi?.data?.data?.data?.[0]?.NEW || 0;
        tabLabels[2].count =
            accountStatsApi?.data?.data?.data?.[0]?.IN_PROGRESS || 0;
        tabLabels[3].count =
            accountStatsApi?.data?.data?.data?.[0]?.APPROVED || 0;
        tabLabels[4].count =
            accountStatsApi?.data?.data?.data?.[0]?.SEND_TO_LEAD || 0;
        tabLabels[5].count =
            accountStatsApi?.data?.data?.data?.[0]?.COMPLETED || 0;
        tabLabels[6].count =
            accountStatsApi?.data?.data?.data?.[0]?.REJECTED || 0;
    }

    const getButtonLabel = () => {
        if (
            tabLabels[selectedLabel].name ===
            message("createAccount.tab.progress.name")
        ) {
            return message("sharedComponent.table.header.button.sendApproval");
        } else if (
            tabLabels[selectedLabel].name ===
            message("createAccount.tab.sendToLead.name")
        ) {
            return message("sharedComponent.table.header.button.sendToBCOS");
        } else {
            return "";
        }
    };
    const handleGenerateLink = (leadId) => {
        const data = {
            leadId,
            sendCustomerLink: true,
            sendLoginOtp: false,
        };
        generateLinkData.callback(data);
    };

    const handleShareLink = (leadId) => {
        const data = {
            leadId,
            sendCustomerLink: true,
            sendLoginOtp: false,
        };
        generateLinkData.callback(data);
    };

    const alert = React.useContext(AlertContext);
    const generateLinkData = useWaitCall(
        (data) => generateLink(data),
        [],
        () => {
            setShowOtpModel(false);
            alert.setSuccess("Link generated Successfully");
        },
        () => {
            alert.setError("something went wrong");
        }
    );
    const sendToBcosApi = useWaitCall(
        (data) => sentToBCOS(data),
        [],
        () => {
            alert.setSuccess("Send to BCOS Successfully!");
            setRefresh(!refresh);
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleButtonClick = () => {
        if (
            tabLabels[selectedLabel].name ===
            message("createAccount.tab.sendToLead.name")
        ) {
            if (selectedRows?.length > 0) {
                sendToBcosApi.callback(selectedRows);
            }
        }
    };

    const getSelectedRows = (_e: any) => {
        if (_e?.length > 0) {
            const accountIds: string[] = [];
            const leadIds: number[] = [];

            _e.forEach((element) => {
                accountIds.push(element.accountId);
                leadIds.push(element.leadId);
            });

            setSelectedRows(accountIds);
        } else {
            setSelectedRows([]);
        }
    };

    const getLeadColumns = () => {
        return {
            ...createLeadColumn,
            column: createLeadColumn?.column.map((val) => {
                return { ...val, label: message(val.label) };
            }),
        };
    };

    return (
        <div>
            <Typography variant="poster1">
                {message("layout.sideNav.createAccount")}
            </Typography>
            <Box>
                <Card sx={{ padding: "15px" }}>
                    <TabsComponent
                        handleLabelChange={handleLabelChange}
                        selectedLable={selectedLabel}
                        labels={tabLabels}
                        panelSx={{ padding: "24px" }}
                        tabsSx={{ borderBottom: 1, borderColor: "divider" }}
                    />
                    {/* <Search /> */}
                    <SpinnerWrapper isLoading={accountApi.isLoading}>
                        <TableData
                            setExportList={setExportList}
                            isAccount={true}
                            getSelectedRows={getSelectedRows}
                            tabName={`${accountApi.data?.data?.count || 0} ${
                                tabLabels[selectedLabel].header
                            }`}
                            count={accountApi.data?.data?.count || 0}
                            data={accountApi?.data?.data?.data || []}
                            columnData={getLeadColumns()}
                            buttonLabel={getButtonLabel()}
                            handleExport={handleExport}
                            icon={false}
                            handlePageChange={handlePageChange}
                            handleRowsPerPage={handleRowsPerPage}
                            handleButtonClick={handleButtonClick}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            pageAction={(row) => {
                                return (
                                    <>
                                        <Tooltip
                                            title={message(
                                                "createAccount.table.column.link.formLink"
                                            )}
                                            placement="top"
                                            arrow
                                        >
                                            <IconButton
                                                disabled={
                                                    row.status ===
                                                        ACCOUNT_STATUS_MAPPING.PENDING_FOR_APPROVAL ||
                                                    userContextInfo?.role !==
                                                        ROLES.SALES
                                                }
                                            >
                                                <span
                                                    data-testid={`Form-link-${row.leadId}`}
                                                    onClick={() =>
                                                        navigate(
                                                            "/create-account-form?leadId=" +
                                                                row.leadId
                                                        )
                                                    }
                                                >
                                                    <LinkIcon fill="currentColor" />
                                                </span>
                                            </IconButton>
                                        </Tooltip>

                                        {!(
                                            row.status <
                                                ACCOUNT_STATUS_MAPPING.APPROVED ||
                                            row.status >=
                                                ACCOUNT_STATUS_MAPPING.CUSTOMER_FORM_SUBMITTED
                                        ) && (
                                            <Tooltip
                                                title={message(
                                                    "createAccount.table.column.link.createLink"
                                                )}
                                                placement="top"
                                                arrow
                                            >
                                                <IconButton
                                                    disabled={
                                                        userContextInfo?.role !==
                                                        ROLES.SALES
                                                    }
                                                >
                                                    <PlusIcon
                                                        fill="currentColor"
                                                        onClick={() => {
                                                            setLeadId(
                                                                row.leadId
                                                            );
                                                            setShowOtpModel(
                                                                !showOtpModel
                                                            );
                                                        }}
                                                        style={styleLink}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {row.status ==
                                            ACCOUNT_STATUS_MAPPING.CUSTOMER_LINK_SENT && (
                                            <Tooltip
                                                title={message(
                                                    "createAccount.table.column.link.shareLink"
                                                )}
                                                placement="top"
                                                arrow
                                            >
                                                <IconButton
                                                    disabled={
                                                        userContextInfo?.role !==
                                                        ROLES.SALES
                                                    }
                                                >
                                                    <ShareIcon
                                                        fill="#84BD00"
                                                        onClick={() =>
                                                            handleShareLink(
                                                                row.leadId
                                                            )
                                                        }
                                                        style={styleLink}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </>
                                );
                            }}
                        />
                    </SpinnerWrapper>
                    {
                        <ExportExcel
                            exporter={_exporter}
                            fileName={message(tabLabels[selectedLabel].header)}
                            columnList={exportColumnList}
                        />
                    }
                </Card>
            </Box>
            <BasicModal
                open={showOtpModel}
                handleClose={() => setShowOtpModel(!showOtpModel)}
            >
                <div style={{ height: "100vh" }}>
                    <CreateLink
                        isLoading={generateLinkData.isLoading}
                        onSubmit={() => handleGenerateLink(leadId)}
                        onClose={() => setShowOtpModel(!showOtpModel)}
                    />
                </div>
            </BasicModal>
        </div>
    );
};

export default CreateAccount;
