import React, { useState, useRef } from "react";
import { css } from "@emotion/react";
import {
    Grid,
    FormControl,
    Box,
    FormControlLabel,
    Checkbox,
    Button,
    TextInput,
    Typography,
    BasicModal,
    OtpComponent,
} from "../../../sharedComponent";

import { styled } from "@mui/material/styles";
import { IAttestInput } from "../../../../interface/InputField";
import SignatureCanvas from "react-signature-canvas";
import "./index.scss";
import useTrans from "../../../../hooks/useTranslation";
import { attestInput } from "../../../../utils/constants";
import Link from "@mui/material/Link";

const errorStyle = {
    border: "2px solid red",
};
const AttestOnView = {
    padding: "0rem",
    border: "0rem",
};
const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));

const InputError = styled("span")(() => ({
    color: "red",
}));

const AttestContainer = styled(Box)(
    (props: { isBackgorund?: boolean } = { isBackgorund: true }) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.75rem 0rem;
        width: 20rem;
        height: auto;
        background: ${props?.isBackgorund
            ? `linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71`
            : ""};
        border: 1px solid #eaebeb;
        border-radius: 0.5rem;
    `
);

const AttestBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1.125rem 0rem 0.75rem",
    width: "90%",
    height: "150px",
    background: "#FFFFFF",
    border: "1px solid #EAEBEB",
    borderRadius: "0.5rem",
    margin: "auto",
}));

const HRuler = styled("hr")(() => ({
    width: "90%",
    textAlign: "left",
    margin: "auto",
    borderTop: "1px solid #84BD00",
}));

const ClearButton = styled("button")(() => ({
    border: "none",
    color: "#84BD00",
    background: "#FFFFFF",
    margin: "0.313rem 1rem 0.313rem auto",
}));

const Image = styled("img")(() => ({
    margin: "auto",
    height: "80%",
    width: "80%",
}));

const ColorButton = styled(Button)(() => ({
    borderRadius: "0.5rem",
    backgroundColor: "#84BD00",
    marginLeft: "auto",
    width: "auto",
    height: "1.75rem",
    margin: "0.313rem 1rem 0.313rem auto",
}));

const OtpInput = styled(TextInput)(
    () => css`
        background: white;
        border: 1px solid #ccc;
        padding: 4px;
        margin-top: 6px;
        margin-left: 16px;
    `
);

const AttestInput = ({
    xs,
    md,
    label = "",
    name,
    disabled = false,
    error,
    register,
    handleClick,
    isView = undefined,
    otpView = false,
    otpVerified = false,
    handleSubmitOtp,
    // generateSignatureOTP,
    dateFieldName,
    validation,
    leadId,
}: IAttestInput) => {
    const [message] = useTrans();
    const sigCanvas = useRef(null);
    const imageInfo = validation && validation
        ? register(name, ...validation)
        : register(name) ;
    const [state, setState] = useState({
        otpBox: false,
    });
    const [showOtpModel, setShowOtpModel] = React.useState(false);

    // const otp = register("signOtp")

    const handleClear = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
        imageInfo.onChange({ target: { value: "" } });
        dateFieldName &&
            register(dateFieldName).onChange({ target: { value: "" } });
        setState({ ...state, otpBox: false });
    };
    const handleApply = (e, set = false) => {
        if (sigCanvas.current) {
            if (!sigCanvas.current.isEmpty() && otpView && !set) {
                setState({ ...state, otpBox: true });
                handleSubmitOtp && setShowOtpModel(true);
            }

            if (!otpView || set) {
                const URL = sigCanvas.current
                    .getTrimmedCanvas()
                    .toDataURL("image/png");
                imageInfo.onChange({ target: { value: URL } });
                handleClick && handleClick(e);
            }
        }
    };

    const onSignEnd = () => {
        if (otpView) {
            setState({ ...state, otpBox: false });
        }
    };

    const submitOtp = async (otp: string) => {
        try {
            const isValid = await handleSubmitOtp(otp);
            if (isValid && sigCanvas.current) {
                setShowOtpModel(false);
                const URL = sigCanvas.current
                    .getTrimmedCanvas()
                    .toDataURL("image/png");
                imageInfo.onChange({ target: { value: URL } });
                handleClick && handleClick(null, otp);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Grid item xs={xs} md={md}>
            <FormControl fullWidth>
                {label && <FormLabel>{label}</FormLabel>}
                <AttestContainer
                    isBackgorund={!isView}
                    style={
                        imageInfo?.error
                            ? errorStyle
                            : isView
                              ? AttestOnView
                              : null
                    }
                >
                    <AttestBox
                        style={
                            isView
                                ? {
                                      width: "100%",
                                      border: "0rem",
                                      padding: "0rem",
                                  }
                                : null
                        }
                    >
                        {imageInfo?.value ? (
                            <Image src={imageInfo.value} alt="signature" />
                        ) : !disabled ? (
                            <SignatureCanvas
                                penColor="#84BD00"
                                canvasProps={{ className: "sigCanvas" }}
                                ref={sigCanvas}
                                onEnd={onSignEnd}
                                clearOnResize={false}
                            />
                        ) : (
                            <Box component="div" className="sigCanvas"></Box>
                        )}
                        {!isView ? (
                            <>
                                <HRuler />
                                <ClearButton
                                    type="button"
                                    disabled={disabled}
                                    onClick={handleClear}
                                >
                                    {message(attestInput.crear)}
                                </ClearButton>
                            </>
                        ) : (
                            !imageInfo?.value && <HRuler />
                        )}
                    </AttestBox>

                    {!isView && (
                        <Box
                            component="div"
                            sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                width: "100%",
                            }}
                        >
                            <ColorButton
                                name={message(attestInput.apply)}
                                data-testid={name}
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={disabled}
                                onClick={handleApply}
                            />
                            <BasicModal
                                open={showOtpModel}
                                handleClose={() =>
                                    setShowOtpModel(!showOtpModel)
                                }
                            >
                                <div style={{ height: "100vh" }}>
                                    <OtpComponent
                                        onSubmit={submitOtp}
                                        isLoading={false}
                                        type={2}
                                        leadId={leadId}
                                    />
                                </div>
                            </BasicModal>

                            {otpView && state.otpBox && !otpVerified && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <TextInput
                                        xs={6}
                                        placeholder="Sign OTP"
                                        md={6}
                                        {...register("signOtp")}
                                        id="signOtp"
                                        isView={isView}
                                        disabled={true}
                                        inputBoxStyle={{
                                            background: "white",
                                            border: "1px solid #ccc",
                                            width: "50% !important",
                                            padding: "6px 16px",
                                            height: "1rem",
                                            marginTop: 6,
                                            marginLeft: 16,
                                            textAlign: "center",
                                            "&:focus": {
                                                border: "1px solid #555",
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Box>
                    )}
                </AttestContainer>

                {error && <InputError>{error}</InputError>}
            </FormControl>
        </Grid>
    );
};

export default AttestInput;
