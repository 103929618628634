/* eslint-disable no-useless-escape */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";
import {
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack,
    InputLabel,
    Typography,
    Grid,
    InputAdornment,
    Button,
} from "../../../sharedComponent";
import { leadsDataStyle } from "../styleConstant";
import ButtonComponent from "../../../sharedComponent/button/Button";
import LeadsInfoListData from "./leadInformation";
import { ReactComponent as EditIcon } from "../../../../assets/pen.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-can.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/plus.svg";
import SpinnerWrapper from "../../../sharedComponent/spinWrapper";
import useTrans from "../../../../hooks/useTranslation";
import useAlert from "../../../../hooks/useAlert";
import useForm from "../../../../hooks/useForm";
import TimeRangePicker from "../../../sharedComponent/timeRangePicker";
import MultipleCheckboxes from "../../../sharedComponent/multipleCheckboxes";
import { checkboxData } from "../../../../utils/apiData";
import { ILocation } from "../../../../interfaces/edit-lead";
import { AddressMap } from "../../../AddressMap";
import { TextInput, NumberInput } from "../../../sharedComponent";
import { updateLeadsInfo } from "../../../../services/LeadsInfoApi";
import TextAreaInput from "../../../sharedComponent/InputField/TextAreaInput/TextAreaInput";
import useCall, { useWaitCall } from "../../../../hooks/useCall";
import { getNearByPlace, setCreatelace } from "../../../../services/NfisApi";

import {
    required,
    lengthValidate,
    rangeValidate,
    customPhoneValidator,
    openingHoursValidate,
    openingDaysValidate,
    max,
    validatePriceLevel,
    number,
    exactMatch,
} from "../../../../validation/validation";
import { LeadSourceWrapper } from "./LeadStyle";
import ListItemComp from "../../../sharedComponent/listItems/ListItems";
import { AlertContext } from "../../../../context/alterContext";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../../../utils/global";
const LeadInfoList = (props: any) => {
    const { leadData, isLoading, refresh, isEditPermission,isMapEditable } = props;
    const [leads, setLeads] = React.useState(leadData as any);
    const alert = useContext(AlertContext);
    const { locations } =
        leadData != undefined || (leadData != null && leadData);
    const defaultValues = {
        latitude: leadData?.locations?.latitude,
        longitude: leadData?.locations?.longitude,
        shopNumber: leadData?.locations?.shopNumber,
        blockNumber: leadData?.locations?.blockNumber,
        address1: leadData?.locations?.address1,
        address2: leadData?.locations?.address2,
        pincode: leadData?.locations?.pincode,
        city: leadData?.locations?.city,
        district: leadData?.locations?.district,
        province: leadData?.locations?.province,
        shopName: leadData?.shopName,
        importDate: dateFormat(leadData?.importDate),
        placeId: leadData?.placeId,
        contactPerson: leadData?.contactPerson,
        contactNumber:
            (leadData?.contactNumber && leadData?.contactNumber.join(",")) ||
            "",
        timing: leadData?.timing,
        regTiming: dayjs(leadData?.regTiming),
        status: leadData?.status,
        openday: leadData?.openday,
        priceLevelMin: leadData?.priceLevelMin,
        priceLevelMax: leadData?.priceLevelMax,
        rating: leadData?.rating,
        reviews: leadData?.reviews,
        noOfSeats: leadData?.noOfSeats,
        leadSourceUrl: leadData?.leadSourceUrl,
        note: leadData?.note,
    };

    const duplicateCheckRequest = {
        latitude: leadData?.locations?.latitude,
        longitude: leadData?.locations?.longitude,
        radius: "1000",
        pageNo: 1,
        pageSize: 100,
    };

    const getDuplicatecheckApi = useCall(
        () => getNearByPlace(duplicateCheckRequest),
        [],
        []
    );

    const [inputFields, setInputFields] = React.useState(
        leadData?.contactNumber || [" "]
    ) as any;

    const { id, _edit } = useParams();
    React.useEffect(() => {
        if (_edit === "edit" && isEditPermission) {
            setEdit(!edit);
        }
    }, []);
    const [message] = useTrans();
    const { registerAlert } = useAlert();
    const leadForm = useForm(defaultValues as any);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        resetValue,
        resetValidation,
        isValid,
        setValues,
    } = leadForm;

    const contactInfo = register(
        "contactNumber",
        required(
            message(
                "sharedComponent.listDivider.leadInfo.leadInformationForm.phoneValid"
            )
        ),
        customPhoneValidator(
            message(
                "sharedComponent.listDivider.leadInfo.leadInformationForm.phoneValid"
            )
        )
    );
    React.useEffect(() => {
        resetValidation();
    }, []);
    const [openDay, setOpenDay] = React.useState(checkboxData);
    const isNew = id === "-1";
    const [edit, setEdit] = React.useState(isNew);
    const [locData, setLocData] = React.useState<ILocation>({
        latitude: Number(leadData?.locations?.latitude),
        longitude: Number(leadData?.locations?.longitude),
        address1: leadData?.locations?.address1,
        address2: leadData?.locations?.address2,
    });

    useEffect(() => {
        if (defaultValues) {
            resetValue({ ...(defaultValues as any) });
        }
    }, []);

    const addFields = () => {
        if (inputFields?.length <= 3) {
            const newfield = "";
            setInputFields([...inputFields, newfield]);
            handleContactChange({ target: { value: "" } }, inputFields.length);
        }
    };

    const removeFields = (index: any) => {
        const data = [...inputFields];
        if (inputFields?.length > 1) {
            data.splice(index, 1);
            setInputFields(data);
            contactInfo.onChange({ target: { value: data?.join(",") } });
        }
    };
    const DivLight = () => {
        return <Divider light />;
    };
    const defaultProps = (sxVal: any, val: any) => {
        return {
            sx: {
                borderRadius: "10px",
                height: sxVal?.height ? sxVal?.height : "8vh",
            },
            disableUnderline: true,
            maxLength: val?.maxLength,
            pattern: val?.pattern,
            inputmode: val?.inputmode,
            endAdornment: val?.endAdornment,
        };
    };
    const leadNewId = isNew ? "-1" : leadData?.id;
    const updateApiData = useWaitCall(
        (data) => updateLeadsInfo(leadNewId, data),
        {},
        () => {
            alert.setSuccess("Save Successfully");
            setEdit(false);
            isNew ? navigate("/branchleads") : refresh();
        },
        (error) => {
            alert.setError(
                error?.data?.errors
                    ? error?.data?.errors.map((item) => item.message).join(", ")
                    : "Update data something went wrong"
            );
        }
    );

    const onSubmit = async (data) => {
        event.preventDefault();
        const apiData = {
            locations: {
                latitude: data?.latitude,
                longitude: data?.longitude,
                shopNumber: data?.shopNumber,
                blockNumber: data?.blockNumber,
                address1: data?.address1,
                address2: data?.address2,
                pincode: data?.pincode,
                city: data?.city,
                province: data?.province,
                district: data?.district,
            },
            leads: {
                shopName: data?.shopName,
                contactPerson: data?.contactPerson,
                contactNumber: [...inputFields],
                timing: data?.timing || null,
                regTiming: data?.regTiming || null,
                status: edit ? 2 : 1,
                openday: data?.openday,
                priceLevelMin: data?.priceLevelMin || null,
                priceLevelMax: data?.priceLevelMax || null,
                rating: data?.rating,
                reviews: data?.reviews,
                noOfSeats: data?.noOfSeats,
                note: data?.note,
            },
        };
        updateApiData.callback(apiData);

        if (updateApiData?.error) {
            alert.setError("Unable to save lead info, please try again later");

            return;
        }

        if (_edit === "edit") {
            const { pathname } = location;
            navigate(`/leadInfo/${leadData?.id}/${pathname.split("/")[3]}`);
        } else {
            setEdit(edit);
        }
    };

    const address = (location) => {
        return (
            location.blockNumber +
            "," +
            location.address1 +
            "," +
            location.address2 +
            "," +
            location.pincode +
            "," +
            location.city +
            "," +
            location.district +
            "," +
            location.province
        );
    };

    const createApiData = useWaitCall(
        (data) => setCreatelace(id as any, data),
        {},
        (result) => {
            alert.setSuccess("Save Successfully");
            //setLeads(result)
            refresh();
        },
        (error) => {
            alert.setError(
                error?.data?.errors
                    ? error?.data?.errors.map((item) => item.message).join(", ")
                    : "Create data something went wrong"
            );
        }
    );

    const updateCreatePlaceId = () => {
        const data = {
            placeName: leadData?.shopName,
            secondName: leadData?.contactPerson,
            placeAddress: address(leadData?.locations),
            placeLat: leadData?.locations?.latitude,
            placeLong: leadData?.locations?.longitude,
            telNo:
                leadData?.contactNumber?.length > 0
                    ? leadData?.contactNumber[0]
                    : null,
            placeRemark: leadData?.note,
            channelID: 1,
            subChannelID: 5,
            dataSrc: "Lead Generation System MVP1",
            dataSrcID: "LG0203",
        };
        createApiData.callback(data);
    };

    const handleCancel = () => {
        setLeads({ ...leadData });
        setLocData({});
        setEdit(!edit);
        refresh();
    };

    const handleCoordsUpdate = (lat: number, long: number) => {
        setValues({ latitude: lat, longitude: long });
    };

    const handleContactChange = (e, index) => {
        inputFields[index] = e.target.value;
        setInputFields([...inputFields]);
        contactInfo.onChange({ target: { value: inputFields?.join(",") } });
    };
    return (
        <>
            <List
                sx={leadsDataStyle}
                component="nav"
                aria-label="mailbox folders"
            >
                {registerAlert()}
                <ListItem>
                    <ListItemText
                        primary={message(
                            "sharedComponent.listDivider.leadInfo.leadInformation"
                        )}
                        primaryTypographyProps={{
                            color: "#47474A",
                            fontSize: "1.5rem",
                            fontFamily: "'DB Helvethaica X 65 Med'",
                        }}
                    />
                    {isEditPermission && (
                        <span
                            style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "#919295",
                                display: "flex",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                if (defaultValues) {
                                    setEdit(true);
                                    leadForm.validate();
                                }
                            }}
                        >
                            {edit ? (
                                ""
                            ) : (
                                <EditIcon
                                    width={12}
                                    height={12}
                                    fill={"#919295"}
                                    cursor={"pointer"}
                                />
                            )}
                            {edit ? "" : message("edit")}
                        </span>
                    )}
                </ListItem>
                {!edit ? (
                    <SpinnerWrapper isLoading={isLoading}>
                        <LeadsInfoListData
                            data={props?.leadData}
                            isLoading={createApiData.isLoading}
                            duplicateData={getDuplicatecheckApi}
                            updateCreatePlaceId={updateCreatePlaceId}
                        />
                    </SpinnerWrapper>
                ) : (
                    <SpinnerWrapper isLoading={isLoading}>
                        <form onSubmit={handleSubmit(onSubmit)} aria-label="add-new-lead-form" data-testid="lead-form">
                            <Grid container spacing={2}>
                                <TextInput
                                    xs={6}
                                    md={6}
                                    {...register(
                                        "shopName",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireShopName"
                                            )
                                        ),
                                        lengthValidate(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.charLimit"
                                            )
                                        )
                                    )}
                                    label={
                                        message(
                                            "sharedComponent.listDivider.leadInfo.shopName"
                                        ) + "*"
                                    }
                                    id="shopName"
                                    labelType="row"
                                />
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <TextInput
                                    xs={6}
                                    md={6}
                                    disabled={true}
                                    label={
                                        message(
                                            "sharedComponent.listDivider.leadInfo.importDate"
                                        ) + "*"
                                    }
                                    {...register("importDate")}
                                    id="importDate"
                                    labelType="row"
                                />
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <TextInput
                                    xs={6}
                                    md={6}
                                    disabled={true}
                                    label={message(
                                        "sharedComponent.listDivider.leadInfo.placeId"
                                    )}
                                    {...register("placeId", null)}
                                    id="placeId"
                                    labelType="row"
                                />
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <TextInput
                                    xs={6}
                                    md={6}
                                    label={
                                        message(
                                            "sharedComponent.listDivider.leadInfo.contactPerson"
                                        ) + "*"
                                    }
                                    {...register(
                                        "contactPerson",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireContactPerson"
                                            )
                                        ),
                                        lengthValidate(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.charLimit"
                                            )
                                        )
                                    )}
                                    id="contactPerson"
                                    labelType="row"
                                />
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                {inputFields?.map((inp, idx) => {
                                    return (
                                        <>
                                            {idx === 0 ? (
                                                ""
                                            ) : (
                                                <Grid
                                                    key={idx}
                                                    item
                                                    xs={3}
                                                    md={3}
                                                ></Grid>
                                            )}
                                            <NumberInput
                                                key={idx}
                                                {...contactInfo}
                                                error={
                                                    contactInfo?.error?.split(
                                                        ","
                                                    )[idx]
                                                }
                                                onChange={(event) => {
                                                    handleContactChange(
                                                        event,
                                                        idx
                                                    );
                                                }}
                                                value={inputFields?.[idx]}
                                                xs={6}
                                                md={6}
                                                id="contactNumber"
                                                labelType="row"
                                                label={
                                                    idx == 0 &&
                                                    message(
                                                        "sharedComponent.listDivider.leadInfo.contactNumber"
                                                    ) + "*"
                                                }
                                                labelVal={
                                                    idx === 0 ? true : false
                                                }
                                                testId="contact-num"
                                            />

                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "row",
                                                    gap: "15px",
                                                }}
                                            >
                                                {idx > 0 && (
                                                    <div
                                                        style={{
                                                            margin: "5px 0px 0px 10px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            removeFields(idx)
                                                        }
                                                    >
                                                        <TrashIcon
                                                            height={12}
                                                            width={12}
                                                            fill={"#919295"}
                                                            cursor={"pointer"}
                                                        />
                                                    </div>
                                                )}
                                                {idx ===
                                                    (inputFields?.length || 0) -
                                                        1 && (
                                                    <div
                                                        style={{
                                                            color: "#919295",
                                                            fontSize: "1.2rem",
                                                            fontFamily:
                                                                "DB Helvethaica X 65 Regular",
                                                            textDecoration:
                                                                "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={addFields}
                                                    >
                                                        <PlusIcon
                                                            width={10}
                                                            height={20}
                                                            fill={"#919295"}
                                                        />
                                                        Add More
                                                    </div>
                                                )}
                                            </div>
                                            {idx !== 0 ? (
                                                ""
                                            ) : (
                                                <Grid item xs={3} md={3}></Grid>
                                            )}
                                        </>
                                    );
                                })}
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <TextInput
                                    xs={2}
                                    md={2}
                                    placeholder="Shop No."
                                    {...register(
                                        "shopNumber",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireShopNumber"
                                            )
                                        )
                                    )}
                                    label={
                                        message(
                                            "sharedComponent.listDivider.leadInfo.address"
                                        ) + "*"
                                    }
                                    id="shopNumber"
                                    labelType="row"
                                />
                                <TextInput
                                    xs={3}
                                    md={3}
                                    placeholder="Block No."
                                    {...register(
                                        "blockNumber",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireBlockNumber"
                                            )
                                        )
                                    )}
                                    label={""}
                                    id="blockNumber"
                                    labelVal={true}
                                    labelType="row"
                                />

                                <TextInput
                                    xs={4}
                                    md={4}
                                    placeholder="Address one"
                                    {...register(
                                        "address1",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireAddressOne"
                                            )
                                        ),
                                        lengthValidate(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.charLimit"
                                            )
                                        )
                                    )}
                                    label={""}
                                    id="address1"
                                    labelVal={true}
                                    labelType="row"
                                />
                                <Grid item md={3}></Grid>
                                <TextInput
                                    xs={9}
                                    md={9}
                                    placeholder="Address two"
                                    {...register(
                                        "address2",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireAddressOne"
                                            )
                                        ),
                                        lengthValidate(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.charLimit"
                                            )
                                        )
                                    )}
                                    label={""}
                                    id="address2"
                                    labelVal={true}
                                    labelType="row"
                                />

                                <Grid item md={3}></Grid>
                                <TextInput
                                    xs={4.5}
                                    md={4.5}
                                    placeholder="City"
                                    {...register("city", required(""))}
                                    label={""}
                                    id="city"
                                    labelVal={true}
                                    labelType="row"
                                />

                                <TextInput
                                    xs={4.5}
                                    md={4.5}
                                    placeholder="District"
                                    {...register("district", required(""))}
                                    label={""}
                                    id="district"
                                    labelVal={true}
                                    labelType="row"
                                />
                                <Grid item md={3}></Grid>
                                <TextInput
                                    xs={4.5}
                                    md={4.5}
                                    placeholder="Province"
                                    {...register(
                                        "province",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireProvince"
                                            )
                                        )
                                    )}
                                    label={""}
                                    id="province"
                                    labelVal={true}
                                    labelType="row"
                                />

                                <TextInput
                                    xs={3}
                                    md={3}
                                    placeholder="Pincode"
                                    {...register(
                                        "pincode",
                                        number(),
                                        exactMatch("", 5),
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requirePinCode"
                                            )
                                        )
                                    )}
                                    label={""}
                                    id="pincode"
                                    labelVal={true}
                                    labelType="row"
                                />
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <NumberInput
                                    xs={4}
                                    md={4}
                                    label={
                                        message(
                                            "sharedComponent.listDivider.leadInfo.geoData"
                                        ) + "*"
                                    }
                                    {...register(
                                        "latitude",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireLatitude"
                                            )
                                        ),
                                        rangeValidate(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.rangeLimitLat"
                                            ),
                                            90
                                        )
                                    )}
                                    id="latitude"
                                    labelType="row"
                                />
                                <NumberInput
                                    xs={4}
                                    md={4}
                                    label={""}
                                    labelVal={false}
                                    {...register(
                                        "longitude",
                                        required(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.requireLongitude"
                                            )
                                        ),
                                        rangeValidate(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.rangeLimitLng"
                                            ),
                                            180
                                        )
                                    )}
                                    id="latitude"
                                    labelType="row"
                                />
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <NumberInput
                                    xs={3}
                                    md={3}
                                    label={message(
                                        "sharedComponent.listDivider.leadInfo.priceLevel"
                                    )}
                                    {...register(
                                        "priceLevelMin",
                                        validatePriceLevel("")
                                    )}
                                    id="priceLevelMin"
                                    labelType="row"
                                    // iconData={"฿"}
                                />
                                <NumberInput
                                    xs={3}
                                    md={3}
                                    label={""}
                                    labelVal={false}
                                    {...register(
                                        "priceLevelMax",
                                        validatePriceLevel("")
                                    )}
                                    inputPropsVal={
                                        <InputAdornment position="end">
                                            ฿
                                        </InputAdornment>
                                    }
                                    id="priceLevelMax"
                                    labelType="row"
                                    iconData={"฿"}
                                />
                            </Grid>
                            <DivLight />
                            <Grid container p={1}>
                                <Grid
                                    md={3}
                                    lg={3}
                                    spacing={5}
                                    textAlign={"end"}
                                >
                                    {" "}
                                    <InputLabel
                                        sx={{
                                            marginTop: "5%",
                                            padding: "0px 10px",
                                        }}
                                    >
                                        <Typography sx={{ color: "#919295" }}>
                                            Opening Hours
                                        </Typography>
                                    </InputLabel>
                                </Grid>
                                <Grid md={9} lg={9}>
                                    <Stack
                                        flexWrap={"wrap"}
                                        width={"100%"}
                                        justifyContent={"flex-start"}
                                    >
                                        <TimeRangePicker
                                            {...register(
                                                "timing",
                                                openingHoursValidate("")
                                            )}
                                            // handleTimeChange={handleTimeChange}
                                            // startTime={leads?.timing}
                                            // endTime={leads?.regTiming}
                                        />
                                        <MultipleCheckboxes
                                            {...register(
                                                "openday",
                                                openingDaysValidate("")
                                            )}
                                            openDay={openDay}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <NumberInput
                                    xs={4.5}
                                    md={4.5}
                                    label={message(
                                        "sharedComponent.listDivider.leadInfo.ratingReview"
                                    )}
                                    {...register("rating", number())}
                                    inputPropsVal={defaultProps(
                                        {},
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    Ratings
                                                </InputAdornment>
                                            ),
                                        }
                                    )}
                                    registerVal={register(
                                        "reviews",
                                        max(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.maxFour"
                                            ),
                                            4
                                        )
                                    )}
                                    subinputPropsVal={defaultProps(
                                        {},
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    Reviews
                                                </InputAdornment>
                                            ),
                                        }
                                    )}
                                    id="rating"
                                    labelType="row"
                                    iconData={"Ratings"}
                                />
                                <NumberInput
                                    xs={4.5}
                                    md={4.5}
                                    label={""}
                                    labelVal={false}
                                    {...register(
                                        "reviews",
                                        max(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.maxFour"
                                            ),
                                            4
                                        )
                                    )}
                                    id="reviews"
                                    labelType="row"
                                    iconData={"Reviews"}
                                />
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <NumberInput
                                    xs={6}
                                    md={6}
                                    type="number"
                                    label={message(
                                        "sharedComponent.listDivider.leadInfo.noOfSeats"
                                    )}
                                    {...register(
                                        "noOfSeats",
                                        number(),
                                        max(
                                            message(
                                                "sharedComponent.listDivider.leadInfo.leadInformationForm.maxThree"
                                            ),
                                            3
                                        )
                                    )}
                                    id="noOfSeats"
                                    labelType="row"
                                />
                            </Grid>
                            <DivLight />
                            <Grid container>
                                <ListItemComp
                                    name={message(
                                        "sharedComponent.listDivider.leadInfo.leadSource"
                                    )}
                                    value={
                                        register("leadSourceUrl").value ? (
                                            <LeadSourceWrapper>
                                                <span>
                                                    {message(
                                                        "sharedComponent.listDivider.leadInfo.facebook"
                                                    )}
                                                </span>
                                                {">"}
                                                <a
                                                    href={
                                                        register(
                                                            "leadSourceUrl"
                                                        ).value
                                                    }
                                                >
                                                    {message(
                                                        "sharedComponent.listDivider.leadInfo.post"
                                                    )}
                                                </a>
                                            </LeadSourceWrapper>
                                        ) : (
                                            message(
                                                "sharedComponent.listDivider.leadInfo.manual"
                                            )
                                        )
                                    }
                                />
                            </Grid>
                            <DivLight />
                            <Grid container spacing={2}>
                                <TextAreaInput
                                    xs={9}
                                    md={9}
                                    id="note"
                                    label={message(
                                        "sharedComponent.listDivider.leadInfo.notes"
                                    )}
                                    {...register("note", null)}
                                    labelType="row"
                                />
                            </Grid>
                            <DivLight />
                            <div>
                                <AddressMap
                                    onUpdateCoords={handleCoordsUpdate}
                                    longitude={Number(
                                        leadData?.locations?.longitude || 15
                                    )}
                                    latitude={Number(
                                        leadData?.locations?.latitude || 102
                                    )}
                                    isEditable={isMapEditable}
                                />
                            </div>
                            <DivLight />
                            <Stack
                                direction="row"
                                sx={{
                                    justifyContent: "right",
                                    marginTop: "20px",
                                    gap: "10px",
                                }}
                            >
                                {!isNew && (
                                    <ButtonComponent
                                        name={message(
                                            "sharedComponent.listDivider.leadInfo.button.cancel"
                                        )}
                                        variant="outlined"
                                        color="success"
                                        type="submit"
                                        sx={{
                                            borderRadius: "32px",
                                            color: "#84BD00",
                                            gap: "8px",
                                            textTransform: "none",
                                        }}
                                        onClick={handleCancel}
                                    />
                                )}
                                <Button
                                    data-testid="save-changes"
                                    isLoading={updateApiData.isLoading}
                                    name={message(
                                        "sharedComponent.listDivider.leadInfo.button.saveChanges"
                                    )}
                                    variant="contained"
                                    color="success"
                                    type="submit"
                                    disabled={!isValid}
                                />
                            </Stack>
                        </form>
                    </SpinnerWrapper>
                )}
            </List>
        </>
    );
};

export default LeadInfoList;
