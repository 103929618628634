import React from "react";
import dayjs from "dayjs";
import {
    Typography,
    Divider,
    Box,
    Paper,
    Grid,
    Checkbox,
    FormControlLabel,
    InputLabel,
    Stack,
    InputAdornment,
    Button,
    DateInput,
    TextInput,
    AttestInput,
} from "../../sharedComponent/index";
import { styled } from "@mui/system";
import { ReactComponent as HomeIcon } from "../../../assets/house-solid.svg";
import { ReactComponent as LocationIcon } from "../../../assets/location-dot.svg";
import { ReactComponent as AddIcon } from "../../../assets/plus-solid.svg";
import AddressWrapper from "./address";
import useTrans from "../../../hooks/useTranslation";
import { aggrement } from "../../../utils/constants";
import { ICreateAccountForm } from "../type";
import { ReactComponent as RemoveIcon } from "../../../assets/trash-can.svg";
import { addressRequired, required } from "../../../validation/validation";

const StackStyledWrapper = styled(Stack)({
    flexDirection: "row",
    alignItems: "center",
});

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
}));

const HRuler = styled("hr")(() => ({
    width: "100%",
    textAlign: "left",
    borderColor: "#f8f8f8",
}));

const SignatureStyledWrapper = styled("div")(() => ({
    display: "flex",
    gap: ".5rem",
}));
// const styleIcon = {
//     color: "#919295",
//     marginLeft: "auto",
// }

const Ptext = styled("p")(() => ({
    fontFamily: "Arial",
    fontSize: "0.975rem",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: "#000000",
    backgroundColor: "#ffffff",
    marginTop: "auto",
}));

const memorandum = ({
    register,
    handleNext,
    handleBack,
    isView,
    isSales,
    setValues,
}: ICreateAccountForm) => {
    const [message] = useTrans();
    const [address, setAddress] = React.useState([]);
    const [check, setCheck] = React.useState(false);

    const addressField = register(
        "memorandumAddress",
        required(""),
        addressRequired("")
    );
    const addresses = Array.isArray(addressField?.value)
        ? addressField.value
        : [{ defaultAddress: false }];

    const handleCheckboxChange = () => {
        if (isView) {
            return;
        }

        setCheck(!check);
    };

    const handleAddressChange = (values, index) => {
        if (values?.defaultAddress) {
            addresses.forEach((element) => {
                element.defaultAddress = false;
            });
        }

        addresses[index] = values;

        setValues({ memorandumAddress: addresses });
    };

    const handleAddAddress = () => {
        if (isSales) {
            setValues({
                memorandumAddress: [...addresses, { defaultAddress: false }],
            });
        }
    };
    // const handleDelete = (index) => {
    //     addresses.splice(index, 1)
    //     setValues({
    //         memorandumAddress: [...addresses],
    //     })
    // }

    return (
        <>
            <Box component={Paper} sx={{ borderRadius: "1rem" }}>
                <Typography variant="poster2" sx={{ padding: "1rem 2rem" }}>
                    {message(aggrement.title)}
                </Typography>
                <Divider />
                <Box
                    sx={{
                        padding: "1rem 2rem",
                        fontFamily: "DB Helvethaica X 55 Regular",
                        fontWeight: 400,
                        fontSize: "1.1428571428571428rem",
                    }}
                >
                    <StackStyledWrapper>
                        <Typography>{message(aggrement.date)}:</Typography>{" "}
                        <DateInput
                            name="date"
                            register={register}
                            isView={isView}
                            disabled={true}
                            id="date"
                        />
                    </StackStyledWrapper>

                    <Grid
                        container
                        item
                        spacing={1}
                        columnGap={2}
                        rowGap={2}
                        mt={2}
                        alignItems={"baseline"}
                        flexWrap={"wrap"}
                    >
                        <span>{"    "}</span>
                        <Ptext>{message(aggrement.firstAggrement)}</Ptext>
                        <TextInput
                            xs={5}
                            md={5}
                            name="location"
                            {...register("location")}
                            isView={isView}
                            disabled={true}
                            displayValueOnly={isView}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationIcon
                                            fill="#47474A"
                                            height={20}
                                            width={20}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Ptext>{message(aggrement.branch)}</Ptext>
                        <Ptext>{message(aggrement.secondAggrement)}</Ptext>
                        <TextInput
                            xs={5}
                            md={5}
                            name="buyerProduct"
                            {...register("item")}
                            isView={isView}
                            disabled={!isSales}
                            fullWidth
                        />
                        <Ptext>{message(aggrement.thirdAggrement)}</Ptext>
                        <Ptext> {message(aggrement.with)}</Ptext>
                        <TextInput
                            xs={3}
                            md={3}
                            name="buyerName"
                            value={`${register("firstName")?.value} ${
                                register("lastName")?.value
                            }`}
                            isView={isView}
                            disabled={true}
                        />
                        <Ptext>{message(aggrement.fourthAggrement)}</Ptext>
                        {register("adjustedCreditLimit")?.value > 20000 ? (
                            <DateInput
                                disable={false}
                                name="date2"
                                register={register}
                                isView={isView}
                                disabled={true}
                                id="date2"
                                value={dayjs(register("date2").value)}
                            />
                        ) : (
                            <TextInput
                                xs={5}
                                md={5}
                                name="ldate2"
                                {...register("ldate2")}
                                isView={isView}
                                disabled={true}
                                fullWidth
                                value="-"
                            />
                        )}
                        <Ptext>{message(aggrement.fifthAggrement)}</Ptext>
                    </Grid>
                    <Ptext>{message(aggrement.SixthAggrement)}</Ptext>

                    {addresses.map((item, index) => (
                        <>
                            <StackStyledWrapper>
                                <HomeIcon
                                    fill="#84BD00"
                                    height={15}
                                    width={15}
                                />
                                <Typography variant="poster2">{`${message(
                                    aggrement.address
                                )}`}</Typography>
                                {/* {index > 0 && isSales && (
                  <Typography variant="poster2" style={styleIcon}>
                    <IconButton onClick={() => handleDelete(index)}>
                      <RemoveIcon fill="currentColor" />
                    </IconButton>
                  </Typography>
                )}
                <span>Remove</span> */}
                            </StackStyledWrapper>
                            <AddressWrapper
                                key={index}
                                index={index}
                                defaultValues={item}
                                handleChange={handleAddressChange}
                                isView={isView}
                                isSales={isSales}
                            />
                        </>
                    ))}

                    {address}
                    <Stack
                        flexDirection={"row"}
                        sx={{ color: "#84BD00", alignItems: "center" }}
                        pt={2}
                    >
                        <AddIcon height={10} width={10} />{" "}
                        <Typography onClick={handleAddAddress}>
                            {message(aggrement.addAddress)}
                        </Typography>
                    </Stack>
                    <HRuler />
                    <Typography ml={4} mb={2}>
                        {message(aggrement.seventhAggrement)}
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                        <TextInput
                            multiline
                            fullWidth
                            name="description"
                            {...register("description")}
                            isView={isView}
                            disabled={!isSales}
                            sx={{ backgroundColor: "#FFFFFC" }}
                            placeholder="Enter Description"
                            minRows={3}
                            maxRows={5}
                        />
                        {isView && (
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                0/255
                            </InputLabel>
                        )}
                    </Box>
                    <Typography ml={4}>
                        {message(aggrement.eigthAggrement)}
                    </Typography>
                    <Stack flexDirection={"row"} mt={1} ml={2}>
                        <Box my={{ marginTop: "4px" }}></Box>
                        <Typography>
                            {message(aggrement.nineAggrement)}
                        </Typography>
                    </Stack>
                    <Typography my={4}>
                        {message(aggrement.tenthAggrement)}
                    </Typography>
                </Box>
                <Grid item container spacing={2} p={5}>
                    <Grid item md={6}>
                        <Typography>{message(aggrement.buyer)}</Typography>
                        <AttestInput
                            // xs={6}
                            // md={6}
                            width={400}
                            height={200}
                            label=""
                            register={register}
                            disabled={true}
                            name="buyerSign"
                            id="buyerSign"
                            isView={isView}
                            dateFieldName="buyerSignDate"
                            handleClick={() => {
                                setValues({ buyerSignDate: dayjs() });
                            }}
                        />
                        <SignatureStyledWrapper>
                            <TextInput
                                xs={6}
                                md={6}
                                value={`${register("firstName")?.value} ${
                                    register("lastName")?.value
                                }`}
                                disabled={true}
                                id="buyerSignName"
                                placeholder={message(aggrement.buyerSign)}
                            />
                            <DateInput
                                xs={3}
                                md={3}
                                label=""
                                name={"buyerSignDate"}
                                disabled={true}
                                register={register}
                                id="buyerSignDate"
                                isView={isView}
                            />
                        </SignatureStyledWrapper>
                    </Grid>

                    <Grid item md={6}>
                        <Typography>{message(aggrement.seller)}</Typography>
                        <AttestInput
                            // xs={6}
                            // md={6}
                            width={400}
                            height={200}
                            label=""
                            disabled={!isSales}
                            register={register}
                            name="sellerSign"
                            id="sellerSign"
                            dateFieldName="salesSignDate"
                            isView={isView}
                            handleClick={() => {
                                setValues({ salesSignDate: dayjs() });
                            }}
                        />
                        <SignatureStyledWrapper>
                            <TextInput
                                xs={6}
                                md={6}
                                {...register("sellerSignName")}
                                id="sellerSignName"
                                disabled={!isSales}
                                placeholder={message(aggrement.sellerSign)}
                            />
                            <DateInput
                                xs={3}
                                md={3}
                                label=""
                                name="salesSignDate"
                                register={register}
                                id="salesSignDate"
                                isView={isView}
                                disabled={true}
                            />
                        </SignatureStyledWrapper>
                    </Grid>
                </Grid>
                <Box sx={{ padding: "1rem" }}>
                    <FormControlLabel
                        key={"12"}
                        control={
                            <Checkbox
                                checked={!isView ? check : true}
                                name={"checkbox"}
                                color={isView ? "default" : "primary"}
                                onChange={handleCheckboxChange}
                            />
                        }
                        sx={{ color: "#000000" }}
                        label={
                            <span style={{ fontSize: "1.45rem" }}>
                                {message("account.form.agree")}
                            </span>
                        }
                        style={{
                            opacity: isView ? 0.4 : 1,
                        }}
                    />
                    {!isView && (
                        <Grid item xs={12} md={12} py={5}>
                            <NavButton
                                name={message("account.form.button.previous")}
                                variant="contained"
                                color="success"
                                onClick={handleBack}
                                sx={{
                                    float: "left",
                                }}
                            />
                            <NavButton
                                name={message("account.form.button.next")}
                                variant="contained"
                                color="success"
                                onClick={handleNext}
                                disabled={!check}
                                sx={{
                                    float: "right",
                                }}
                            />
                        </Grid>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default memorandum;
