import React, { useEffect, useContext } from "react";
import dayjs from "dayjs";
import { LoginContext } from "../../../context/loginContext";
import { styled } from "@mui/material/styles";
import { ICreateAccountForm } from "../../pages/type";
import {
    TextInput,
    NumberInput,
    SelectInput,
    Typography,
    Grid,
    DateInput,
    Button,
    AttestInput,
    FormControlLabel,
    Checkbox,
} from "../../sharedComponent";
import { createAccountFormAnalysis } from "../../../utils/constants";
import useTrans from "../../../hooks/useTranslation";
import { ValidateCreditLimit } from "../../../validation/validation";

const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0rem",
    gap: "1.688rem",
    width: "55.5rem",
    left: "27rem",
    top: "8rem",
}));

const FormContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 0rem 2rem",
    width: "55.5rem",
    background: "#FFFFFF",
    boxShadow: "0rem 0.25rem 5rem rgba(0, 0, 0, 0.04)",
    borderRadius: "1rem",
}));

const FormHead = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "0.063rem solid #EAEBEB",
    borderRadius: "1rem 16rem 0rem 0rem",
}));

const HRuler = styled("hr")(() => ({
    width: "100%",
    textAlign: "left",
    color: "#84BD00",
    margin: "0px",
}));

const FormFrame = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "0rem",
    width: "49.5rem",
}));

const SubFormTitle = styled("p")(() => ({
    fontSize: "1.5rem",
    fontWeight: "600",
    margin: "10px 0px",
}));

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
}));

const RowGrid = styled(Grid)(() => ({
    alignItems: "center",
}));

const CreateAccountStepAnalysis = ({
    handleNext,
    handleBack,
    register,
    isView,
    setValues,
    fieldValue,
    isSales,
    isManager,
    id,
}: ICreateAccountForm) => {
    const [check, setCheck] = React.useState(false);
    const handleCheckboxChange = () => {
        if (isView) {
            return;
        }

        setCheck(!check);
    };

    const [message] = useTrans();

    const { loginInfo, setloginContext } = useContext(LoginContext);

    useEffect(() => {
        setloginContext({ isLogout: true });
    }, [loginInfo.isLogout]);

    const titleInfo = register("title");

    const titleOption = [
        { value: 1, label: message("account.form.aggrement.mr") },
        { value: 2, label: message("account.form.aggrement.mrs") },
        { value: 3, label: message("account.form.aggrement.miss") },
    ];
    let selectedTitle = titleOption.find(
        (item) => item.value === titleInfo?.value
    );
    selectedTitle = selectedTitle ? selectedTitle.label : "";

    function handleVolumeChangeItem(
        e: React.ChangeEvent<HTMLInputElement>,
        purchaseQuantityKey: string,
        pricePerUnitKey: string,
        daysPerOrderKey: string,
        endKey: string
    ): void {
        const purchaseQuantity =
            purchaseQuantityKey === e.target.id
                ? Number(e.target.value)
                : Number(fieldValue[purchaseQuantityKey]) || 0;
        const pricePerUnit =
            pricePerUnitKey === e.target.id
                ? Number(e.target.value)
                : Number(fieldValue[pricePerUnitKey]) || 0;
        const daysPerOrder =
            daysPerOrderKey === e.target.id
                ? Number(e.target.value)
                : Number(fieldValue[daysPerOrderKey]) || 0;
        const creditTerm = Number(fieldValue["CreditTerm"]) || 0;
        const creditDay = Number(fieldValue["CreditDay"]) || 0;
        const totalValue =
            purchaseQuantity && pricePerUnit && daysPerOrder
                ? (purchaseQuantity * pricePerUnit) / daysPerOrder
                : 0;

        const sumKeyList = [
            "purchaseValuePork",
            "purchaseValueChicken",
            "purchaseValueEgg",
            "purchaseValueDuck",
            "purchaseValueFish",
            "purchaseValueProcessedMeat",
            "purchaseValueProcessedFood",
            "purchaseValueLivePig",
            "purchaseValueLiveChicken",
            "purchaseValueFreshFish",
            "purchaseValueOthers",
        ];

        const totalPurchaseValueList = sumKeyList.map((val) => {
            if (endKey === val) {
                return totalValue;
            } else {
                return fieldValue[val] || 0;
            }
        });

        const totalPurchaseValue = totalPurchaseValueList.reduce((a, b) => {
            return Number(a) + Number(b);
        }, 0);

        const totalCreditLimit = getTotalCreditLimit(
            creditTerm,
            creditDay,
            totalPurchaseValue
        );

        setValues({
            [e.target.id]: e.target.value,
            [endKey]: totalValue.toFixed(2),
            totalPurchaseValue: totalPurchaseValue.toFixed(2),
            totalCreditLimit: totalCreditLimit,
            adjustedCreditLimit: totalCreditLimit,
        });
    }

    const getTotalCreditLimit = (creditTerm, creditDay, totalPurchaseValue) => {
        return Math.floor((creditTerm + creditDay) * totalPurchaseValue);
    };
    function handleVolumeCredit(e: React.ChangeEvent<HTMLInputElement>): void {
        const creditTerm =
            "CreditTerm" === e.target.id
                ? Number(e.target.value)
                : Number(fieldValue["CreditTerm"]) || 0;
        const creditDay =
            "CreditDay" === e.target.id
                ? Number(e.target.value)
                : Number(fieldValue["CreditDay"]) || 0;
        const totalPurchaseValue =
            Number(fieldValue["totalPurchaseValue"]) || 0;

        const totalCreditLimit = getTotalCreditLimit(
            creditTerm,
            creditDay,
            totalPurchaseValue
        );
        setValues({
            [e.target.id]: e.target.value,
            totalCreditLimit,
            adjustedCreditLimit: totalCreditLimit,
        });
    }
    const isAdjustedCreditLimit = register("isAdjustedCreditLimit");
    return (
        <Main>
            <FormContainer id={id}>
                <FormHead>
                    <Typography sx={{ mx: 5 }} variant="poster2">
                        {message(createAccountFormAnalysis.title)}
                    </Typography>
                </FormHead>
                <FormFrame>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <SubFormTitle>
                                {message(createAccountFormAnalysis.secOne)}
                            </SubFormTitle>
                        </Grid>
                        {!isView ? (
                            <>
                                <SelectInput
                                    xs={3}
                                    md={3}
                                    label={message(
                                        createAccountFormAnalysis.labelTitle
                                    )}
                                    options={titleOption}
                                    {...titleInfo}
                                    isView={isView}
                                    disabled={!isSales}
                                    name="title"
                                    id="Title"
                                />

                                <TextInput
                                    xs={5}
                                    md={5}
                                    {...register("firstName")}
                                    id="firstName"
                                    disabled={!isSales}
                                    label={message(
                                        createAccountFormAnalysis.labelFirstName
                                    )}
                                    isView={isView}
                                />
                                <TextInput
                                    xs={4}
                                    md={4}
                                    label={message(
                                        createAccountFormAnalysis.labelLastName
                                    )}
                                    {...register("lastName")}
                                    id="lastName"
                                    disabled={!isSales}
                                    isView={isView}
                                />
                                <TextInput
                                    xs={6}
                                    md={6}
                                    label={message(
                                        createAccountFormAnalysis.labelNationalID
                                    )}
                                    {...register("nationalId")}
                                    id="nationalId"
                                    disabled={!isSales}
                                    isView={isView}
                                />
                            </>
                        ) : (
                            <>
                                {/* <SelectInput
              xs={3}
              md={3}
              labelSize={2}
              label={message(createAccountFormAnalysis.customerName)}
              options={[
                  { value: message("account.form.aggrement.mr"), label: message("account.form.aggrement.mr") },
                  { value: message("account.form.aggrement.mrs"), label: message("account.form.aggrement.mrs") },
                  { value: message("account.form.aggrement.miss"), label: message("account.form.aggrement.miss") },
              ]}
              {...titleInfo}
              labelType="row"
              isView={isView}
              disabled={!isSales}
              onChange={(e) =>
                titleInfo.onChange({ target: { value: e.target.value } })
              }
              name="title"
              id="Title"
            /> */}
                                <TextInput
                                    xs={9}
                                    md={9}
                                    labelSize={3}
                                    labelType="row"
                                    label={message(
                                        createAccountFormAnalysis.customerName
                                    )}
                                    value={`${selectedTitle} ${
                                        register("firstName").value
                                    } ${register("lastName").value}`}
                                    id="firstName"
                                    disabled={!isSales}
                                    isView={isView}
                                />

                                <TextInput
                                    xs={9}
                                    md={9}
                                    labelSize={3}
                                    label={message(
                                        createAccountFormAnalysis.labelNationalID
                                    )}
                                    {...register("nationalId")}
                                    labelType="row"
                                    id="nationalId"
                                    disabled={!isSales}
                                    isView={isView}
                                />
                            </>
                        )}
                        <HRuler />

                        <Grid item xs={12} md={12}>
                            <SubFormTitle>
                                {" "}
                                {message(createAccountFormAnalysis.secTwo)}{" "}
                            </SubFormTitle>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {!isView
                                    ? ""
                                    : message(
                                          createAccountFormAnalysis.labelColProduct
                                      )}
                            </Grid>
                            <Grid item xs={3} md={3}>
                                {message(
                                    createAccountFormAnalysis.labelColTotalPurchaseQntity
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                            >
                                {message(
                                    createAccountFormAnalysis.labelColPricePerUnit
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                            >
                                {message(
                                    createAccountFormAnalysis.labelColDaysPerOrder
                                )}
                            </Grid>
                            <Grid item xs={2} md={2}>
                                {message(
                                    createAccountFormAnalysis.labelColPurchaseValue
                                )}
                            </Grid>
                        </Grid>
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowPork
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqPork")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqPork",
                                        "ppuPork",
                                        "daysPerOrderPork",
                                        "purchaseValuePork"
                                    )
                                }
                                id="pqPork"
                                iconData={"KG"}
                                disabled={!isSales}
                                isView={isView}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuPork")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqPork",
                                        "ppuPork",
                                        "daysPerOrderPork",
                                        "purchaseValuePork"
                                    )
                                }
                                isView={isView}
                                id="ppuPork"
                                disabled={!isSales}
                                iconData={"฿/KG"}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderPork")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqPork",
                                        "ppuPork",
                                        "daysPerOrderPork",
                                        "purchaseValuePork"
                                    )
                                }
                                disabled={!isSales}
                                id="daysPerOrderPork"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValuePork")}
                                disabled={true}
                                id="purchaseValuePork"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowChicken
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqChicken")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqChicken",
                                        "ppuChicken",
                                        "daysPerOrderChicken",
                                        "purchaseValueChicken"
                                    )
                                }
                                id="pqChicken"
                                iconData={"KG"}
                                disabled={!isSales}
                                isView={isView}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuChicken")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqChicken",
                                        "ppuChicken",
                                        "daysPerOrderChicken",
                                        "purchaseValueChicken"
                                    )
                                }
                                id="ppuChicken"
                                iconData={"฿/KG"}
                                disabled={!isSales}
                                isView={isView}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderChicken")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqChicken",
                                        "ppuChicken",
                                        "daysPerOrderChicken",
                                        "purchaseValueChicken"
                                    )
                                }
                                id="daysPerOrderChicken"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueChicken")}
                                disabled={true}
                                id="purchaseValueChicken"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(createAccountFormAnalysis.labelRowEgg)}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqEgg")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqEgg",
                                        "ppuEgg",
                                        "daysPerOrderEgg",
                                        "purchaseValueEgg"
                                    )
                                }
                                id="pqEgg"
                                iconData={message("account.form.button.egg")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuEgg")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqEgg",
                                        "ppuEgg",
                                        "daysPerOrderEgg",
                                        "purchaseValueEgg"
                                    )
                                }
                                id="ppuEgg"
                                iconData={`฿/${message(
                                    "account.form.button.egg"
                                )}`}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderEgg")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqEgg",
                                        "ppuEgg",
                                        "daysPerOrderEgg",
                                        "purchaseValueEgg"
                                    )
                                }
                                id="daysPerOrderEgg"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueEgg")}
                                disabled={true}
                                id="purchaseValueEgg"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowDuck
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqDuck")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqDuck",
                                        "ppuDuck",
                                        "daysPerOrderDuck",
                                        "purchaseValueDuck"
                                    )
                                }
                                id="pqDuck"
                                iconData={"KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuDuck")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqDuck",
                                        "ppuDuck",
                                        "daysPerOrderDuck",
                                        "purchaseValueDuck"
                                    )
                                }
                                id="ppuDuck"
                                iconData={"฿/KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderDuck")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqDuck",
                                        "ppuDuck",
                                        "daysPerOrderDuck",
                                        "purchaseValueDuck"
                                    )
                                }
                                id="daysPerOrderDuck"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueDuck")}
                                disabled={true}
                                id="purchaseValueDuck"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowFish
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqFish")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqFish",
                                        "ppuFish",
                                        "daysPerOrderFish",
                                        "purchaseValueFish"
                                    )
                                }
                                id="pqFish"
                                iconData={"KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuFish")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqFish",
                                        "ppuFish",
                                        "daysPerOrderFish",
                                        "purchaseValueFish"
                                    )
                                }
                                id="ppuFish"
                                iconData={"฿/KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderFish")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqFish",
                                        "ppuFish",
                                        "daysPerOrderFish",
                                        "purchaseValueFish"
                                    )
                                }
                                id="daysPerOrderFish"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueFish")}
                                disabled={true}
                                id="purchaseValueFish"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowProcessedMeat
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqProcessedMeat")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqProcessedMeat",
                                        "ppuProcessedMeat",
                                        "daysPerOrderProcessedMeat",
                                        "purchaseValueProcessedMeat"
                                    )
                                }
                                id="pqProcessedMeat"
                                iconData={"KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuProcessedMeat")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqProcessedMeat",
                                        "ppuProcessedMeat",
                                        "daysPerOrderProcessedMeat",
                                        "purchaseValueProcessedMeat"
                                    )
                                }
                                id="ppuProcessedMeat"
                                iconData={"฿/KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderProcessedMeat")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqProcessedMeat",
                                        "ppuProcessedMeat",
                                        "daysPerOrderProcessedMeat",
                                        "purchaseValueProcessedMeat"
                                    )
                                }
                                id="daysPerOrderProcessedMeat"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueProcessedMeat")}
                                disabled={true}
                                id="purchaseValueProcessedMeat"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowProcessedFood
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqProcessedFood")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqProcessedFood",
                                        "ppuProcessedFood",
                                        "daysPerOrderProcessedFood",
                                        "purchaseValueProcessedFood"
                                    )
                                }
                                id="pqProcessedFood"
                                iconData={"KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuProcessedFood")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqProcessedFood",
                                        "ppuProcessedFood",
                                        "daysPerOrderProcessedFood",
                                        "purchaseValueProcessedFood"
                                    )
                                }
                                id="ppuProcessedFood"
                                iconData={"฿/KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderProcessedFood")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqProcessedFood",
                                        "ppuProcessedFood",
                                        "daysPerOrderProcessedFood",
                                        "purchaseValueProcessedFood"
                                    )
                                }
                                id="daysPerOrderProcessedFood"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueProcessedFood")}
                                disabled={true}
                                id="purchaseValueProcessedFood"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowLivePig
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqLivePig")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqLivePig",
                                        "ppuLivePig",
                                        "daysPerOrderLivePig",
                                        "purchaseValueLivePig"
                                    )
                                }
                                id="pqLivePig"
                                iconData={"KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuLivePig")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqLivePig",
                                        "ppuLivePig",
                                        "daysPerOrderLivePig",
                                        "purchaseValueLivePig"
                                    )
                                }
                                id="ppuLivePig"
                                iconData={"฿/KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderLivePig")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqLivePig",
                                        "ppuLivePig",
                                        "daysPerOrderLivePig",
                                        "purchaseValueLivePig"
                                    )
                                }
                                id="daysPerOrderLivePig"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueLivePig")}
                                disabled={true}
                                id="purchaseValueLivePig"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowLiveChicken
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqLiveChicken")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqLiveChicken",
                                        "ppuLiveChicken",
                                        "daysPerOrderLiveChicken",
                                        "purchaseValueLiveChicken"
                                    )
                                }
                                id="pqLiveChicken"
                                iconData={"KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuLiveChicken")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqLiveChicken",
                                        "ppuLiveChicken",
                                        "daysPerOrderLiveChicken",
                                        "purchaseValueLiveChicken"
                                    )
                                }
                                id="ppuLiveChicken"
                                iconData={"฿/KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderLiveChicken")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqLiveChicken",
                                        "ppuLiveChicken",
                                        "daysPerOrderLiveChicken",
                                        "purchaseValueLiveChicken"
                                    )
                                }
                                id="daysPerOrderLiveChicken"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueLiveChicken")}
                                disabled={true}
                                id="purchaseValueLiveChicken"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowFreshFish
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqFreshFish")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqFreshFish",
                                        "ppuFreshFish",
                                        "daysPerOrderFreshFish",
                                        "purchaseValueFreshFish"
                                    )
                                }
                                id="pqFreshFish"
                                iconData={"KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuFreshFish")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqFreshFish",
                                        "ppuFreshFish",
                                        "daysPerOrderFreshFish",
                                        "purchaseValueFreshFish"
                                    )
                                }
                                id="ppuFreshFish"
                                iconData={"฿/KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderFreshFish")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqFreshFish",
                                        "ppuFreshFish",
                                        "daysPerOrderFreshFish",
                                        "purchaseValueFreshFish"
                                    )
                                }
                                id="daysPerOrderFreshFish"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueFreshFish")}
                                disabled={true}
                                id="purchaseValueFreshFish"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 1 : 3}
                                md={!isView ? 1 : 3}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowOthers
                                )}
                            </Grid>
                            <NumberInput
                                xs={3}
                                md={3}
                                {...register("pqOthers")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqOthers",
                                        "ppuOthers",
                                        "daysPerOrderOthers",
                                        "purchaseValueOthers"
                                    )
                                }
                                id="pqOthers"
                                iconData={"KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("ppuOthers")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqOthers",
                                        "ppuOthers",
                                        "daysPerOrderOthers",
                                        "purchaseValueOthers"
                                    )
                                }
                                id="ppuOthers"
                                iconData={"฿/KG"}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("daysPerOrderOthers")}
                                onChange={(e) =>
                                    handleVolumeChangeItem(
                                        e,
                                        "pqOthers",
                                        "ppuOthers",
                                        "daysPerOrderOthers",
                                        "purchaseValueOthers"
                                    )
                                }
                                id="daysPerOrderOthers"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("purchaseValueOthers")}
                                disabled={true}
                                id="purchaseValueOthers"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 9 : 10}
                                md={!isView ? 9 : 10}
                            >
                                {message(
                                    createAccountFormAnalysis.labelRowTotalPurchaseValue
                                )}
                            </Grid>

                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("totalPurchaseValue")}
                                disabled={!isSales}
                                id="totalPurchaseValue"
                                iconData={"฿"}
                                isView={isView}
                            />
                        </RowGrid>
                        <HRuler />
                        {!isView && (
                            <Grid item xs={12} md={12}>
                                <SubFormTitle>
                                    {" "}
                                    {message(
                                        createAccountFormAnalysis.secThree
                                    )}{" "}
                                </SubFormTitle>
                            </Grid>
                        )}
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 2 : 4}
                                md={!isView ? 2 : 4}
                            ></Grid>
                            <Grid
                                item
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                            >
                                {message(
                                    createAccountFormAnalysis.labelColCreditTerm
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                            >
                                {message(
                                    createAccountFormAnalysis.labelColCreditDay
                                )}
                            </Grid>
                            <Grid item xs={2} md={2}>
                                {message(
                                    createAccountFormAnalysis.labelColTotalCreditLimit
                                )}
                            </Grid>
                            <Grid item xs={2} md={2}>
                                {message(
                                    createAccountFormAnalysis.labelColAdjustedCreditLimit
                                )}
                            </Grid>
                        </Grid>
                        <RowGrid container spacing={1}>
                            <Grid
                                item
                                xs={!isView ? 2 : 4}
                                md={!isView ? 2 : 4}
                            >
                                {message(createAccountFormAnalysis.secThree)}
                            </Grid>
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("CreditTerm")}
                                onChange={handleVolumeCredit}
                                id="CreditTerm"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={!isView ? 3 : 2}
                                md={!isView ? 3 : 2}
                                {...register("CreditDay")}
                                onChange={handleVolumeCredit}
                                id="CreditDay"
                                iconData={message("account.form.button.days")}
                                isView={isView}
                                disabled={!isSales}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register("totalCreditLimit")}
                                id="totalCreditLimit"
                                iconData={"฿"}
                                isView={isView}
                                disabled={true}
                            />
                            <NumberInput
                                xs={2}
                                md={2}
                                {...register(
                                    "adjustedCreditLimit",
                                    ValidateCreditLimit(
                                        null,
                                        register("totalCreditLimit")?.value
                                    )
                                )}
                                iconData={"฿"}
                                isView={isView}
                                isBold={isAdjustedCreditLimit?.value}
                                disabled={
                                    !register("isAdjustedCreditLimit")?.value ||
                                    !isSales
                                }
                            />
                        </RowGrid>
                        <RowGrid container spacing={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...isAdjustedCreditLimit}
                                        checked={isAdjustedCreditLimit?.value}
                                        color={!isSales ? "default" : "primary"}
                                        onChange={(e) => {
                                            if (!isSales) {
                                                return;
                                            }

                                            isAdjustedCreditLimit.onChange({
                                                target: {
                                                    value: e.target.checked,
                                                },
                                            });
                                            if (!e.target.checked) {
                                                register(
                                                    "adjustedCreditLimit"
                                                ).onChange({
                                                    target: {
                                                        value: register(
                                                            "totalCreditLimit"
                                                        ).value,
                                                    },
                                                });
                                            }
                                        }}
                                        style={{
                                            opacity: !isSales ? 0.4 : 1,
                                        }}
                                    />
                                }
                                label={
                                    <span style={{ fontSize: "1.45rem" }}>
                                        {message(
                                            createAccountFormAnalysis.labelCheckOne
                                        )}
                                    </span>
                                }
                            />
                        </RowGrid>

                        <AttestInput
                            xs={6}
                            md={6}
                            isView={isView}
                            label={message(
                                createAccountFormAnalysis.labelSignOne
                            )}
                            register={register}
                            name="sellerSign"
                            id="sellerSign"
                            disabled={!isSales}
                            dateFieldName="salesSignDate"
                            handleClick={(e) => {
                                setValues({ salesSignDate: dayjs() });
                            }}
                        />
                        <AttestInput
                            xs={6}
                            md={6}
                            isView={isView}
                            label={message(
                                createAccountFormAnalysis.labelSignTwo
                            )}
                            name="branchManager"
                            register={register}
                            disabled={!isManager}
                            id="branchManager"
                            dateFieldName="branchManagerDate"
                            handleClick={() => {
                                setValues({ branchManagerDate: dayjs() });
                            }}
                        />
                        <TextInput
                            xs={3}
                            md={3}
                            label=""
                            {...register("sellerSignName")}
                            id="sellerSignName"
                            disabled={!isSales}
                            isView={isView}
                        />
                        <DateInput
                            xs={3}
                            md={3}
                            label=""
                            register={register}
                            name={"salesSignDate"}
                            id="salesSignDate"
                            isView={isView}
                            disabled={true}
                        />
                        <TextInput
                            xs={3}
                            md={3}
                            label=""
                            {...register("managerSignName")}
                            id="managerSignName"
                            isView={isView}
                            disabled={!isManager}
                        />

                        <DateInput
                            xs={3}
                            md={3}
                            label=""
                            name={"branchManagerDate"}
                            register={register}
                            id="branchManagerDate"
                            isView={isView}
                            disabled={true}
                        />
                        <FormControlLabel
                            key={"12"}
                            control={
                                <Checkbox
                                    checked={!isView ? check : true}
                                    name={"checkbox"}
                                    color={isView ? "default" : "primary"}
                                    onChange={handleCheckboxChange}
                                    data-testid="checkbox"
                                />
                            }
                            sx={{ color: "#000000" }}
                            label={
                                <span style={{ fontSize: "1.45rem" }}>
                                    {message("account.form.agree")}
                                </span>
                            }
                            style={{
                                opacity: isView ? 0.4 : 1,
                            }}
                        />

                        {!isView && (
                            <Grid item xs={12} md={12} mt={5}>
                                <NavButton
                                    name={message(
                                        "account.form.button.previous"
                                    )}
                                    variant="contained"
                                    color="success"
                                    onClick={handleBack}
                                    sx={{
                                        float: "left",
                                    }}
                                />
                                <NavButton
                                    name={message("account.form.button.next")}
                                    variant="contained"
                                    color="success"
                                    onClick={handleNext}
                                    disabled={!check}
                                    sx={{
                                        float: "right",
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormFrame>
            </FormContainer>
        </Main>
    );
};

export default CreateAccountStepAnalysis;
