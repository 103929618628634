import React from "react";
import { Divider, Grid, styled } from "../../sharedComponent";

interface IList {
    name?: any;
    value?: any;
    icon?: any;
}

const DivLight = () => {
    return <Divider light />;
};

const ListItemComp = (props: IList) => {
    const FormLabel = styled("label")(() => ({
        fontSize: "1.25rem",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
    }));
    interface iTable {
        gridSize?: number;
        IsleftAlign?: boolean;
    }
    const TableWrapper = styled("div")<iTable>(
        ({ gridSize = 2, IsleftAlign }) => ({
            display: "flex",
            width: "100%",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            alignItems: "center",
            flex: 1,
            justifyContent: "flex-start",
            wordBreak: "break-word",
            "& > div,b": {
                flex: gridSize ? gridSize : 1,
                display: "flex",
                justifyContent: IsleftAlign ? "flex-start" : "flex-end",
                textAlign: "initial",
                "& > b": {
                    marginRight: "5px",
                },
                margin: "15px 10px",
            },
            "& > div:first-child,b:first-child": {
                flex: 1,
                justifyContent: "flex-start",
            },
        })
    );

    return (
        <>
            <TableWrapper IsleftAlign={true}>
                <Grid item md={3}>
                    <FormLabel>{props?.name}</FormLabel>
                </Grid>
                <Grid item xs={6} md={6} spacing={5} alignItems={"inherit"}>
                    {props?.value}
                    {props?.icon}
                </Grid>
            </TableWrapper>
            <DivLight />
        </>
    );
};

export default ListItemComp;
