import * as React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Checkbox,
    Pagination,
    Box,
    InputLabel,
    Select,
    MenuItem,
} from "../../sharedComponent";
import { statusConvert, statusCheck } from "../../../utils/statusConversion";
import { dateFormat } from "../../../utils/global";
import { ReactComponent as SuitCaseIcon } from "../../../assets/suitcase.svg";
import { ReactComponent as LocationIcon } from "../../../assets/location-dot.svg";
import { Iorder, Itab } from "../../../components/pages/type";
import {
    EnhancedTableHead,
    EnhancedTableToolbar,
} from "./enhancedTableToolbar/EnhancedTableToolbar";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import SpinnerWrapper from "../spinWrapper";
import useTrans from "../../../hooks/useTranslation";
import useForm from "../../../hooks/useForm";
import { useCommonContext } from "@betagro/ui-common";
function descendingComparator(a: any, b: any, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order: Iorder, orderBy: string) {
    return order.order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array: any, comparator: any) {
    //used any
    const stabilizedThis =
        array.length > 1 && array?.map((el: any, index: any) => [el, index]);
    stabilizedThis &&
        stabilizedThis?.sort((a: any, b: any) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
    return stabilizedThis && stabilizedThis?.map((el: any) => el[0]);
}

const DEFAULT_ORDER = "asc";
const DEFAULT_ORDER_BY = "calories";
const DEFAULT_ROWS_PER_PAGE = 5;

const TableData = ({
    tabName,
    data = [],
    icon,
    buttonLabel,
    count,
    handlePageChange = () => {
        return;
    },
    isLoading,
    modal,
    openModal,
    handleModelClose,
    pageAction,
    handleExport,
    setExportList,
}: Itab) => {
    const navigate = useNavigate();
    const [message] = useTrans();
    const [order, setOrder] = React.useState(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const visibleRows = data;
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const rowsPerPageData = [5, 10, 15, 20];
    const [paddingHeight, setPaddingHeight] = React.useState(0);
    // const [dense, setDense] = React.useState(false);

    React.useEffect(() => {
        let rowsOnMount = stableSort(
            data,
            getComparator({ order: "asc" }, DEFAULT_ORDER_BY)
        );
        rowsOnMount =
            rowsOnMount &&
            rowsOnMount?.slice(
                0 * DEFAULT_ROWS_PER_PAGE,
                0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
            );
        //setVisibleRows(rowsOnMount);
    });

    React.useEffect(() => {
        if (setExportList) setExportList(selected);
    }, [selected]);

    React.useEffect(() => {
        //setVisibleRows(data);
    }, [data.length]);

    const handleRequestSort = React.useCallback(
        (event: React.MouseEvent<HTMLInputElement>, newOrderBy: string) => {
            const isAsc = orderBy === newOrderBy && order === "asc";
            const toggledOrder = isAsc ? "desc" : "asc";
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = stableSort(
                data,
                getComparator(toggledOrder as any, newOrderBy)
            );
            const updatedRows =
                sortedRows &&
                sortedRows?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                );

            //setVisibleRows(updatedRows);
        },
        [order, orderBy, page, rowsPerPage]
    );

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = data.map((n: any) => n.id);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };

    const handleClick = (
        event: React.MouseEvent<HTMLTableCellElement>,
        id: string
    ) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleLeadDetail = (
        event: React.MouseEvent<HTMLTableCellElement>,
        row: any
    ) => {
        if (!row?.archive) {
            const name = message(tabName).replace(/\s+/g, "-");
            if (row?.id != null) {
                navigate(`/leadInfo/${row?.id}/${name}`);
            }
        }
    };

    const handleChangePage = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, newPage: any) => {
            setPage(newPage);
            handlePageChange(newPage, rowsPerPage);
            const sortedRows = stableSort(
                data,
                getComparator(order as any, orderBy)
            ); //type mismatch in order
            const updatedRows =
                sortedRows &&
                sortedRows?.slice(
                    newPage * rowsPerPage,
                    newPage * rowsPerPage + rowsPerPage
                );
            //setVisibleRows(updatedRows);
            // Avoid a layout jump when reaching the last page with empty data.
            const numEmptyRows =
                newPage > 0
                    ? Math.max(0, (1 + newPage) * rowsPerPage - data.length)
                    : 0;

            const newPaddingHeight = 53 * numEmptyRows;
            setPaddingHeight(newPaddingHeight);
        },
        [order, orderBy, rowsPerPage]
    );

    const handleRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(updatedRowsPerPage);
        setPage(1);
        handlePageChange(1, updatedRowsPerPage);
        const sortedRows = stableSort(
            data,
            getComparator(order as any, orderBy)
        ); //type mismatch in order
        const updatedRows =
            sortedRows &&
            sortedRows?.slice(
                0 * updatedRowsPerPage,
                0 * updatedRowsPerPage + updatedRowsPerPage
            );
        //setVisibleRows(updatedRows);
        // There is no layout jump to handle on the first page.
        setPaddingHeight(0);
    };
    // const handleChangeDense = (event) => {
    //   setDense(event.target.checked);
    // };
    const isSelected = (id: string) => selected.indexOf(id) !== -1;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    return (
        <>
            <EnhancedTableToolbar
                numSelected={selected.length}
                count={count}
                name={tabName}
                icon={icon}
                buttonLabel={buttonLabel}
                handleExport={handleExport}
                selected={selected}
                modal={modal}
                openModal={openModal}
                handleModelClose={handleModelClose}
            />
            <TableContainer sx={{ width: "98%" }}>
                <Table
                //sx={{ minWidth: 750 }}
                >
                    <SpinnerWrapper height={"100px"} isLoading={isLoading}>
                        <>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order as any}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={count | 0}
                            />
                            <TableBody>
                                {!visibleRows && (
                                    <TableRow>
                                        <TableCell colSpan={50}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    height: "200px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {"No data"}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {
                                    visibleRows.length > 0  ?
                                        visibleRows?.map(
                                            (row: any, index: number) => {
                                                const isItemSelected =
                                                    isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                const { leadSalesMappings } =
                                                    row != undefined && row;
                                                const { branches } =
                                                    row?.leadBranchMappings !=
                                                        undefined &&
                                                    row.leadBranchMappings;
                                                const { dueDate, userId } =
                                                    leadSalesMappings !=
                                                        undefined &&
                                                    leadSalesMappings;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={
                                                            isItemSelected
                                                        }
                                                        tabIndex={-1}
                                                        key={`${index}`}
                                                        selected={
                                                            isItemSelected
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                        data-testid="row-checkbox"
                                                    >
                                                        <TableCell
                                                            padding="checkbox"
                                                            onClick={(
                                                                event: React.MouseEvent<HTMLTableCellElement>
                                                            ) =>
                                                                handleClick(
                                                                    event,
                                                                    row?.id
                                                                )
                                                            }
                                                        >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={
                                                                    isItemSelected
                                                                }
                                                                inputProps={{
                                                                    "aria-labelledby":
                                                                        labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            data-testid={
                                                                labelId
                                                            }
                                                            scope="row"
                                                            // padding="none"
                                                            onClick={(
                                                                event: React.MouseEvent<HTMLTableCellElement>
                                                            ) =>
                                                                handleLeadDetail(
                                                                    event,
                                                                    row
                                                                )
                                                            }
                                                        >
                                                            {` ${
                                                                row?.shopName
                                                                    ?.length >
                                                                15
                                                                    ? `${row?.shopName?.substring(
                                                                          0,
                                                                          15
                                                                      )}....`
                                                                    : row?.shopName
                                                            }`}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row?.channel
                                                                ? row?.channel
                                                                      ?.channelName
                                                                : "-"}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row?.subChannel
                                                                ? row
                                                                      ?.subChannel
                                                                      ?.subChannelName
                                                                : "-"}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {dateFormat(
                                                                row.importDate
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {dueDate
                                                                ? dateFormat(
                                                                      dueDate
                                                                  )
                                                                : "-"}
                                                        </TableCell>

                                                        <TableCell
                                                            align="left"
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                            }}
                                                        >
                                                            <span>
                                                                <LocationIcon />{" "}
                                                                {branches?.branchName
                                                                    ? branches?.branchName
                                                                    : "-"}
                                                            </span>
                                                            <span>
                                                                <SuitCaseIcon />{" "}
                                                                {userId
                                                                    ? userId
                                                                    : "-"}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {statusCheck(
                                                                message(
                                                                    statusConvert(
                                                                        row?.status
                                                                    )
                                                                ),
                                                                message
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {pageAction &&
                                                                pageAction(
                                                                    row,
                                                                    index,
                                                                    tabName
                                                                )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )
                                     : []
                                }
                                {/* {paddingHeight > 0 && (
                <TableRow
                  style={{
                    height: paddingHeight,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                            </TableBody>
                        </>
                    </SpinnerWrapper>
                </Table>
            </TableContainer>
            <Box
                component="div"
                className="table-controll"
                sx={{ display: "flex", alignItem: "baseline" }}
            >
                <Box
                    component="span"
                    className="page-display-count"
                >{`${page}-${visibleRows?.length} of ${Math.ceil(count)}`}</Box>
                <InputLabel id="simple-select-label">Rows per page</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows per page"
                    onChange={handleRowsPerPage}
                >
                    {rowsPerPageData?.map((rowItem, idx) => {
                        return (
                            <MenuItem key={idx} value={rowItem}>
                                {rowItem}
                            </MenuItem>
                        );
                    })}
                </Select>
                <Pagination
                    count={Math.ceil(count / rowsPerPage)}
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    onChange={handleChangePage}
                    page={page}
                />
            </Box>

            {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
        </>
    );
};
export default TableData;
