import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack,
} from "../../../sharedComponent";
import { ReactComponent as ChevronDown } from "../../../../assets/chevron-down-solid.svg";
import ButtonComponent from "../../../sharedComponent/button/Button";
import useTrans from "../../../../hooks/useTranslation";
import BasicModal from "../../../sharedComponent/modal";
import AssignModal from "../../leadsDashBoard/assignModal/AssignModal";
import {
    updateLeadsInfo,
    setLeadMarkAsfailed,
    setLeadMarkAsSuccesfull,
} from "../../../../services/LeadsInfoApi";
import useCall, { useWaitCall } from "../../../../hooks/useCall";
import { AlertContext } from "../../../../context/alterContext";
import { getBackgroundInfo } from "../../../../services/BackgroundgetApi";
import { ReactComponent as LocationIcon } from "../../../../assets/location-dot.svg";
import { useCommonContext } from "@betagro/ui-common";
import { dateFormat } from "../../../../utils/global";
import { ActionComponent } from "../../../sharedComponent";
import { ROLES } from "../../../../constants/role.status";

const AssignmentList = (props: any) => {
    const { leadBranchMappings, leadSalesMappings } =
        props.assignData != undefined && props.assignData;
    const { branches } = leadBranchMappings != undefined && leadBranchMappings;
    const { assignDate, assignedBy, dueDate, userId } =
        leadSalesMappings != undefined && leadSalesMappings;
    const archiveVal = props?.assignData?.archive;
    const isEditPermission = props?.isEditPermission;
    const [archiveBtn, setArchiveBtn] = React.useState(archiveVal);
    const commonContext = useCommonContext();
    const [editBtn, setEditBtn] = React.useState(false);
    const [backgroundData, setBackgroundData] = React.useState({} as any);
    const backgroundApiCall = useCall(
        () => getBackgroundInfo(props.leadId as any),
        [],
        []
    );

    React.useEffect(() => {
        if (!backgroundApiCall.isLoading) {
            setBackgroundData(backgroundApiCall?.data?.data?.data);
        }
        if (backgroundApiCall.error) {
            alert.setError("something went wrong");
        }
    }, [backgroundApiCall.isLoading]);

    React.useEffect(() => {
        const roleCheck = commonContext?.userContextInfo?.role === ROLES.SALES;
        setEditBtn(roleCheck);
    }, []);

    const [message] = useTrans();
    const { assignData } = props;

    const assignedStyle = {
        minWidth: "35%",
        bgcolor: "background.paper",
        borderRadius: "16px",
        padding: "0.5rem",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "50%",
    };
    const [open, setOpen] = React.useState(false);

    const openModal = () => {
        setOpen(true);
    };

    const handleModelClose = () => {
        setOpen(false);
    };

    const alert = React.useContext(AlertContext);

    const updateApiData = useWaitCall(
        (data) => updateLeadsInfo(props?.assignData?.id as any, data),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            commonContext.refreshCount();
            props.refresh();
        },
        () => {
            alert.setError("something went wrong");
        }
    );
    const handleArchiveLead = () => {
        const apidata = {
            leads: {
                archive: archiveBtn === true ? false : true,
            },
        };
        updateApiData.callback(apidata);
        setArchiveBtn(!archiveBtn);
    };

    const handleLeadMarkAsSuccessfull = useWaitCall(
        () => setLeadMarkAsSuccesfull(props?.assignData?.id as any),
        [],
        (result) => {
            assignData.isLeadAssigned = true;
            alert.setSuccess("Save Successfully");
            commonContext.refreshCount();
            props.refresh();
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleLeadMarkAsFailed = useWaitCall(
        () => setLeadMarkAsfailed(props?.assignData?.id as any),
        [],
        (result) => {
            assignData.isLeadAssigned = true;
            alert.setSuccess("Save Successfully");
            commonContext.refreshCount();
            props.refresh();
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleMarkAsSuccessfull = () => {
        handleLeadMarkAsSuccessfull.callback();
    };

    const handleMarkAsFailed = () => {
        handleLeadMarkAsFailed.callback();
    };

    const style = {
        position: "absolute",
        right: "1rem",
        bottom: "1.8rem",
    };

    const actions = [
        { name: "Mark as Failed ", handleClick: handleMarkAsFailed },
        { name: "UnAssigned ", handleClick: handleMarkAsSuccessfull },
    ];

    const AssignModalData = () => {
        return (
            <BasicModal open={open} handleClose={handleModelClose}>
                <AssignModal
                    refresh={props.refresh}
                    rowData={props.assignData}
                    handleClose={handleModelClose}
                />
            </BasicModal>
        );
    };
    return (
        <>
            {<AssignModalData />}
            <List
                sx={assignedStyle}
                component="nav"
                aria-label="mailbox folders"
            >
                <ListItem>
                    <ListItemText
                        primary={message(
                            "sharedComponent.listDivider.assignmentList.assignment"
                        )}
                    />
                </ListItem>
                <Divider light />
                <ListItem>
                    <ListItemText
                        primary={message(
                            "sharedComponent.listDivider.assignmentList.assignedBranch"
                        )}
                        secondary={
                            <span>
                                <LocationIcon />{" "}
                                {branches?.branchName
                                    ? branches?.branchName
                                    : "-"}
                            </span>
                        }
                        className="sub-list-item"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={message(
                            "sharedComponent.listDivider.assignmentList.assignedSale"
                        )}
                        secondary={userId}
                        className="sub-list-item"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={message(
                            "sharedComponent.listDivider.assignmentList.dueDate"
                        )}
                        secondary={dueDate ? dateFormat(dueDate) : "-"}
                        className="sub-list-item"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={message(
                            "sharedComponent.listDivider.assignmentList.assigner"
                        )}
                        secondary={assignedBy}
                        className="sub-list-item"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={message(
                            "sharedComponent.listDivider.assignmentList.assignedDate"
                        )}
                        secondary={assignDate ? dateFormat(assignDate) : "-"}
                        className="sub-list-item"
                    />
                </ListItem>
                <Stack spacing={2} direction="column">
                    {(editBtn ||
                        commonContext?.userContextInfo?.role ===
                            ROLES.MANAGER) && (
                        <ButtonComponent
                            name={
                                assignData?.isLeadAssigned === true
                                    ? "Mark as Successful"
                                    : message(
                                          "sharedComponent.listDivider.assignmentList.assignTo"
                                      )
                            }
                            variant="contained"
                            color="success"
                            sx={{
                                borderRadius: "16px",
                                backgroundColor: "#84BD00",
                                textTransform: "none",
                            }}
                            onClick={
                                !assignData?.isLeadAssigned
                                    ? openModal
                                    : handleMarkAsSuccessfull
                            }
                            disabled={
                                leadBranchMappings === null || branches === null
                                    ? true
                                    : assignData?.isLeadAssigned
                                      ? assignData?.status === 7
                                          ? assignData?.leadSalesMappings
                                                ?.empCode !==
                                            commonContext?.userContextInfo
                                                ?.employeeId
                                          : true
                                      : false && backgroundData?.type === true
                            }
                        />
                    )}
                    {isEditPermission && (
                        <ButtonComponent
                            name={message(
                                archiveBtn === true
                                    ? "sharedComponent.listDivider.assignmentList.unarchive"
                                    : "sharedComponent.listDivider.assignmentList.archive"
                            )}
                            // name={archiveBtn}
                            variant="outlined"
                            color="success"
                            sx={{
                                borderRadius: "16px",
                                color: "#84BD00",
                                gap: "8px",
                                textTransform: "none",
                            }}
                            onClick={handleArchiveLead}
                        />
                    )}
                    {!archiveBtn && isEditPermission ? (
                        <ActionComponent actions={actions} styled={style}>
                            {
                                <ChevronDown
                                    height={15}
                                    width={15}
                                    fill={"#919295"}
                                />
                            }
                        </ActionComponent>
                    ) : (
                        ""
                    )}
                </Stack>
                <Divider light sx={{ marginTop: "1rem" }} />
                {/* <ListItem>
          <ListItemText
            primary="Not your location?"
            secondary={""}
          />
        </ListItem> */}
                {/* {props?.assignment && <Divider light />} */}
            </List>
        </>
    );
};

export default AssignmentList;
