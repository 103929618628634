import React from "react";
import { Route, Routes } from "react-router-dom";
import DashBoard from "../components/pages/dashBoard/DashBoard";
import NewLeads from "../components/pages/newLeads";
import OrderManagement from "../components/pages/orderManagement";
import PerformanceTracking from "../components/pages/performanceTracking";
import CreateAccount from "../components/pages/createAccount";
import Inventory from "../components/pages/inventory";
import LeadsDashBoard from "../components/pages/leadsDashBoard/LeadsDashBoard";
import BranchLeads from "../components/pages/branchLeads";
import LeadsDetails from "../components/pages/leadsDetails/LeadsDetails";
import ImportedLeads from "../components/pages/importedLeads";
import ArchivedLeads from "../components/pages/archivedLeads/ArchivedLeads";
import UploadFile from "../components/pages/upload/UploadFile";
const ProtectedRoutes = () => {
    return (
        <Routes>
            <Route
                path="/branchleads"
                element={<LeadsDashBoard t={undefined} i18n={undefined} />}
            />
            <Route
                path="/dashboard"
                element={<DashBoard t={undefined} i18n={undefined} />}
            />
            <Route
                path="/newleads"
                element={<NewLeads t={undefined} i18n={undefined} />}
            />
            <Route path="/leadInfo/:id/:leadName" element={<BranchLeads />} />
            <Route
                path="/leadInfo/:id/:leadName/:_edit"
                element={<BranchLeads />}
            />
            <Route path="/leadInfo/:leadName" element={<BranchLeads />} />
            <Route
                path="/"
                element={<LeadsDashBoard t={undefined} i18n={undefined} />}
            />
            <Route path="/importedleads" element={<ImportedLeads />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/archivedleads" element={<ArchivedLeads />} />
            <Route
                path="/inventory"
                element={<Inventory t={undefined} i18n={undefined} />}
            />
            <Route
                path="/ordermanagement"
                element={<OrderManagement t={undefined} i18n={undefined} />}
            />
            <Route
                path="/performancetracking"
                element={<PerformanceTracking />}
            />
            <Route
                path="/leads-details"
                element={<LeadsDetails tabName={undefined} />}
            />
            <Route path="/upload" element={<UploadFile />} />
        </Routes>
    );
};

export default ProtectedRoutes;
