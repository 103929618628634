import { useState, useEffect } from "react";
import Result from "../utils/resultCreator";

export default function useCall(
    callBack: Function,
    param: any[] = [],
    defaultValue: any = undefined
) {
    const result = new Result(useCall.name, defaultValue);
    const [data, setData] = useState(null);
    useEffect(() => {
        let isRendered = true;
        (async () => {
            try {
                data && setData(null);
                const response = await callBack();
                if (response && isRendered) {
                    const resultData = await response;
                    setData(result.setData(resultData || defaultValue));
                } else if (isRendered) {
                    setData(result.setLoading(false, defaultValue));
                }
            } catch (error) {
                console.error(error);
                isRendered && setData(result.setError(error));
            }
        })();

        return () => {
            isRendered = false;
        };
    }, [...param]);

    return data || result.setLoading(true, defaultValue);
}

export function useWaitCall(
    callBackHandler: Function,
    defaultValue: any,
    successCallBack?: Function,
    errorCallback?: Function
) {
    const result = new Result(useCall.name, defaultValue);
    const [data, setData] = useState(null);
    const callback = (callbackPara: any) => {
        myCall(true, 1, callbackPara);
    };
    const myCall = async (
        isRendered: boolean,
        count: number,
        callbackPara: any
    ) => {
        try {
            setData({
                complete: false,
                ...result.setLoading(true, defaultValue),
            });

            const response = await callBackHandler(callbackPara);

            if (response && isRendered) {
                const resultData = await response;
                setData({
                    complete: true,
                    ...result.setData(resultData || defaultValue),
                });
                successCallBack &&
                    successCallBack({
                        complete: true,
                        ...result.setData(resultData || defaultValue),
                    });
            } else if (isRendered) {
                setData(result.setLoading(false, defaultValue));
            }
        } catch (error) {
            console.error(error);
            isRendered && errorCallback && errorCallback(error?.response);
            isRendered && setData(result.setError(error));
        }
    };

    const callresult = data || result.setLoading(false, defaultValue);

    return { callback, ...callresult };
}
