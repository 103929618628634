import { useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/th";

function RequestInterceptor() {
    const navigate = useNavigate();

    useEffect(() => {
        const interceptorId = axios.interceptors.response.use(
            null,
            (error: AxiosError): Promise<AxiosError> => {
                if (
                    error.response.status === 401 &&
                    location.pathname !== "/login"
                ) {
                    const url = new URL(`${location.origin}/login`);
                    url.searchParams.append(
                        "redirect",
                        location.href || location.origin
                    );
                    navigate(url.pathname + url.search);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptorId);
        };
    }, []);

    // No need to render anything here!
    return null;
}

export default RequestInterceptor;
