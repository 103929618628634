import * as React from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { green, grey } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";

const ColorButton = styled(Button)<ButtonProps>(({ variant }) => ({
    borderRadius: "32px",
    color: variant === "text" ? "#84BD00" : "white",
    border: "1px solid #84BD00",
    padding: "6px 16px",
    textTransform: "none",
    backgroundColor: variant === "text" ? "white" : "#84BD00",
    "&:hover": {
        backgroundColor: variant === "text" ? grey[100] : green[700],
    },
}));

export default function CustomizedButtons(props: any) {
    return (
        <ColorButton
            disabled={props.isLoading}
            {...props}
            startIcon={
                props.isLoading && (
                    <CircularProgress color={"inherit"} size={15} />
                )
            }
            variant={props.variant || "contained"}
        >
            {props.name}
        </ColorButton>
    );
}
