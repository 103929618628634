import * as React from "react";

import { Chip, Typography, Tabs, Tab, Box, Tooltip } from "../index";

interface ITablabels {
    name: string;
    count?: number;
}

function a11yProps(index: Number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        "data-testid": `simple-tabpanel-${index}`,
    };
}
interface TabProps {
    handleLabelChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        newValue: number
    ) => void;
    selectedLable: number;
    labels?: ITablabels[];
    panelSx?: any;
    tabsSx?: any;
    tooltip?: boolean;
}
const TabsComponent = (props: TabProps) => {
    const { labels, tabsSx, handleLabelChange, selectedLable } = props;
    return (
        <>
            <Box sx={tabsSx} style={{ maxWidth: "1200px" }}>
                <Tabs
                    value={selectedLable}
                    onChange={handleLabelChange}
                    aria-label="lead-tabs"
                    className="lead-tabs"
                >
                    {labels?.length > 0
                        ? labels?.map((value: ITablabels, index: number) => {
                              if (props.tooltip)
                                  return (
                                      <Tooltip
                                          title={value.name}
                                          key={value.name}
                                          placement="top"
                                          arrow
                                      >
                                          <Tab
                                              style={{
                                                  width: `${
                                                      100 / labels.length
                                                  }%`,
                                                  padding: "5px",
                                              }}
                                              label={
                                                  <Typography
                                                      style={{
                                                          whiteSpace: "nowrap",
                                                          overflow: "hidden",
                                                          textOverflow:
                                                              "ellipsis",
                                                          fontSize: "1.1rem",
                                                      }}
                                                      variant="subtitle1"
                                                  >
                                                      {value.name}
                                                  </Typography>
                                              }
                                              {...a11yProps(index)}
                                              icon={
                                                  !isNaN(value.count) && (
                                                      <Chip
                                                          className="tab-chip"
                                                          label={value.count}
                                                          size="small"
                                                      />
                                                  )
                                              }
                                              iconPosition="end"
                                              key={`${Math.random()}-${index}`}
                                          />
                                      </Tooltip>
                                  );
                              else
                                  return (
                                      <Tab
                                          style={{
                                              width: `${100 / labels.length}%`,
                                              padding: "5px",
                                          }}
                                          label={
                                              <Typography
                                                  style={{
                                                      whiteSpace: "nowrap",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      fontSize: "1.1rem",
                                                  }}
                                                  variant="subtitle1"
                                              >
                                                  {value.name}
                                              </Typography>
                                          }
                                          {...a11yProps(index)}
                                          icon={
                                              !isNaN(value.count) && (
                                                  <Chip
                                                      className="tab-chip"
                                                      label={value.count}
                                                      size="small"
                                                  />
                                              )
                                          }
                                          iconPosition="end"
                                          key={`${Math.random()}-${index}`}
                                      />
                                  );
                          })
                        : []}
                </Tabs>
            </Box>
        </>
    );
};
export default TabsComponent;
