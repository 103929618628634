import React, { useState, useRef, useContext } from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    CardActionArea,
    Box,
    Container,
    Stack,
} from "../../../sharedComponent";
import Thumbnail from "../../../../utils/assets/thumbnail.jpg";
import LeadInfoImg from "../../../../utils/assets/leadInfoImg.jpg";
import { ReactComponent as CameraIcon } from "../../../../assets/camera.svg";
import "./index.scss";
import TabsComponent from "../../../sharedComponent/tabs/Tabs";
import LeadsDetails from "../LeadsDetails";
import { LeadsDetailsData } from "../../../../utils/apiData";
import useCall, { useWaitCall } from "../../../../hooks/useCall";
import {
    getLeadsInfo,
    updateLeadLeadLogoOrBackdropImg,
} from "../../../../services/LeadsInfoApi";
import { statusCheck, statusConvert } from "../../../../utils/statusConversion";
import SpinnerWrapper from "../../../sharedComponent/spinWrapper";
import { dateFormat } from "../../../../utils/global";
import { useParams } from "react-router-dom";
import useTrans from "../../../../hooks/useTranslation";
import { AlertContext } from "../../../../context/alterContext";
import { useCommonContext } from "@betagro/ui-common";
import BasicBreadcrumbs from "../../../sharedComponent/breadcrumbs";
import { ROLES } from "../../../../constants/role.status";

export default function LeadInfo() {
    const params: any = useParams();
    const [refresh, setRefresh] = useState(false);

    const [message] = useTrans();
    const logoInputRef = useRef(null);
    const bgInputRef = useRef(null);
    const { userContextInfo } = useCommonContext();

    const leadName = params?.leadName;
    const labels = [
        "sharedComponent.tabs.header.leadInfo",
        "sharedComponent.tabs.header.segmentation",
        //"sharedComponent.tabs.header.sales&Gap",
        "sharedComponent.tabs.header.backgroundInfo",
        "sharedComponent.tabs.header.activity",
    ];
    const { id } = useParams();
    const isNew = id === "-1";
    const leadInfoApiCall = useCall(
        () => getLeadsInfo(id as any),
        [refresh],
        []
    );
    // const leadgetInfoBackground = useCall(() => getBackgroundInfo(id as any), [refresh], []);

    const alert = useContext(AlertContext);
    const imageUploadApiCall = useWaitCall(
        (data) => updateLeadLeadLogoOrBackdropImg(data),
        {},
        () => {
            alert.setSuccess("uploaded Successfully");
            setRefresh(!refresh);
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleLogoChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const data = new FormData();
            data.append("File", e.target.files[0]);
            data.append("leadID", id);
            data.append("isLogo", "true");
            imageUploadApiCall.callback(data);
        }
    };

    const handleBGChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const data = new FormData();
            data.append("File", e.target.files[0]);
            data.append("leadID", id);
            imageUploadApiCall.callback(data);
        }
    };

    // triggers the input when the button is clicked
    const handleUploadLogoClick = (e) => {
        e.preventDefault();
        if (userContextInfo?.role === ROLES.SALES) {
            logoInputRef.current.click();
        }
    };
    // triggers the input when the button is clicked
    const handleUploadBGClick = (e) => {
        e.preventDefault();
        if (userContextInfo?.role === ROLES.SALES) {
            bgInputRef.current.click();
        }
    };

    const { data } = leadInfoApiCall != undefined && leadInfoApiCall;
    return (
        <SpinnerWrapper isLoading={leadInfoApiCall.isLoading}>
            <BasicBreadcrumbs
                crumbData={data?.data?.data}
                leadName={leadName}
            />
            <Container maxWidth="xl">
                {!isNew && (
                    <Card sx={{ marginBottom: "-3rem" }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                sx={{ maxHeight: "22rem", objectFit: "fill" }}
                                image={
                                    (data?.data?.data?.backdropImage &&
                                        `/api/file/img/${data?.data?.data?.backdropImage}`) ||
                                    LeadInfoImg
                                }
                                alt="green iguana"
                                data-testid="card-img"
                            />
                            <CardContent>
                                <div>
                                    <Box sx={{ display: "flex" }}>
                                        <Box sx={{ boxShadow: 0 }}>
                                            <div className="image-box">
                                                <img
                                                    src={
                                                        (data?.data?.data
                                                            ?.logoImage &&
                                                            `/api/file/img/${data?.data?.data?.logoImage}`) ||
                                                        Thumbnail
                                                    }
                                                    width="150px"
                                                    height="150px"
                                                    alt=""
                                                />
                                                <input
                                                    ref={logoInputRef}
                                                    type="file"
                                                    hidden={true}
                                                    multiple={false}
                                                    onChange={handleLogoChange}
                                                    data-testid="logo-change"
                                                />
                                                <span
                                                    className="small-image-icon"
                                                    onClick={
                                                        handleUploadLogoClick
                                                    }
                                                >
                                                    <CameraIcon
                                                        width={20}
                                                        height={20}
                                                        fill={"#ffffff"}
                                                    />
                                                </span>
                                            </div>
                                        </Box>
                                        <Box
                                            display={"flex"}
                                            alignItems={"baseline"}
                                        >
                                            <div className="pad">
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="div"
                                                >
                                                    {data?.data?.data?.shopName
                                                        ?.length > 20
                                                        ? `${data?.data?.data?.shopName?.substring(
                                                              0,
                                                              20
                                                          )}....`
                                                        : data?.data?.data
                                                              ?.shopName}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    <span className="zero-visits">
                                                        {
                                                            data?.data?.data
                                                                ?.activities
                                                                ?.visitCount
                                                        }
                                                    </span>{" "}
                                                    {message(
                                                        "pages.leadDetails.leadInfo.visit"
                                                    )}{" "}
                                                    |{" "}
                                                    <span className="zero-visits">
                                                        {
                                                            data?.data?.data
                                                                ?.activities
                                                                ?.callCount
                                                        }
                                                    </span>{" "}
                                                    {message(
                                                        "pages.leadDetails.leadInfo.call"
                                                    )}
                                                </Typography>
                                            </div>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {message(
                                                    "sharedComponent.listDivider.leadInfo.button.lastUpdate"
                                                )}
                                                :-
                                                {dateFormat(
                                                    data?.data?.data?.updatedAt
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                                <Stack direction="row" spacing={1}>
                                    {statusCheck(
                                        message(
                                            statusConvert(
                                                leadInfoApiCall?.data?.data
                                                    ?.data?.status
                                            )
                                        ),
                                        message
                                    )}
                                    {/* <StarSharpIcon sx={{ color: "#FFC53D" }} /> */}
                                </Stack>
                                <Box>
                                    <input
                                        ref={bgInputRef}
                                        type="file"
                                        hidden={true}
                                        multiple={false}
                                        onChange={handleBGChange}
                                    />
                                    <div
                                        className="side-image-upload"
                                        onClick={handleUploadBGClick}
                                    >
                                        <CameraIcon
                                            width={20}
                                            height={20}
                                            fill={"#ffffff"}
                                        />{" "}
                                        <Typography>
                                            {message(
                                                "sharedComponent.leadInfo.editCover"
                                            )}
                                        </Typography>
                                    </div>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                )}
                <SpinnerWrapper isLoading={leadInfoApiCall.isLoading}>
                    <TabsComponent
                        tabsData={LeadsDetailsData}
                        labels={isNew ? [labels[0]] : labels}
                        refresh={() => setRefresh(!refresh)}
                        data={leadInfoApiCall?.data?.data?.data}
                        PanelComp={LeadsDetails}
                        search={false}
                        panelSx={{ padding: "0px" }}
                        tabsSx={{ borderBottom: 1, borderColor: "divider" }}
                    />
                </SpinnerWrapper>
            </Container>
        </SpinnerWrapper>
    );
}
