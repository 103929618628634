import React from "react";
import {
    Divider,
    Typography,
    Card,
    Box,
    Paper,
    CardContent,
    Stack,
    styled,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
} from "../index";
import { ReactComponent as CallIcon } from "../../../assets/phone-solid.svg";
import { IModalList } from "../../../interfaces/modal";
import { ReactComponent as CloseIcon } from "../../../assets/xmark-solid.svg";
import useTrans from "../../../hooks/useTranslation";
import { useCommonContext } from "@betagro/ui-common";
import { ROLES } from "../../../constants/role.status";
import {
    BoxStyle,
    HeaderStyle,
    FooterStyle,
    ContactStack,
    CardStye,
    BodyStyle,
    FooterWraper,
    ButtonWrapper,
    NoDataStyle,
} from "./modalStyle";

interface IModal {
    list: IModalList[];
    isLoading?: boolean;
    handleModalClose: () => void;
    updateCreatePlaceId: () => void;
}

const ModalList = ({
    list,
    isLoading,
    handleModalClose,
    updateCreatePlaceId,
}: IModal) => {
    const [check, setCheck] = React.useState(false);
    const [message] = useTrans();
    const { userContextInfo } = useCommonContext();
    const handleCheckboxChange = () => {
        setCheck(!check);
    };

    const Nodata = () => {
        return (
            <NoDataStyle>
                <Typography variant="subtitle1">No Data Found</Typography>
            </NoDataStyle>
        );
    };

    return (
        <>
            <BoxStyle component={Paper}>
                <HeaderStyle>
                    <Typography variant="poster2" sx={{ padding: "1rem 2rem" }}>
                        {message(
                            "sharedComponent.modalLis.duplicateChecks.heading"
                        )}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{ padding: "1rem 2rem" }}
                        onClick={handleModalClose}
                    >
                        <CloseIcon
                            height={25}
                            width={25}
                            fill={"#47474A"}
                            cursor={"pointer"}
                        />
                    </Typography>
                </HeaderStyle>

                <Divider />
                <BodyStyle>
                    {list.length > 0 ? (
                        list?.map((data, index) => (
                            <CardStye key={index} sx={{ margin: 1 }}>
                                <CardContent>
                                    <Grid item container>
                                        <Grid item lg={5} md={5}>
                                            <ContactStack>
                                                <Typography variant="poster5">
                                                    {message(
                                                        "sharedComponent.modalLis.duplicateChecks.CustomerId"
                                                    )}
                                                    :
                                                    {data.customerSoldTo ||
                                                        "N/A"}
                                                </Typography>
                                                <Typography ml={1}>
                                                    <CallIcon
                                                        fill={"#47474A"}
                                                        height={10}
                                                        width={10}
                                                    />
                                                    {data?.telNo}
                                                </Typography>
                                            </ContactStack>
                                            <Typography variant="subtitle1">
                                                {data.placeName}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={4} md={4}>
                                            <Typography variant="poster5">
                                                {data?.placeAddress}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} md={3}>
                                            <Typography variant="subtitle1">
                                                {data.placeLat},{" "}
                                                {data.placeLong}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardStye>
                        ))
                    ) : (
                        <Nodata />
                    )}
                </BodyStyle>
                <FooterWraper>
                    <FooterStyle>
                        <FormControlLabel
                            key={"checkbox"}
                            control={
                                <Checkbox
                                    defaultChecked
                                    name={"checkbox"}
                                    disabled={list.length < 1}
                                    checked={check}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label={message(
                                "sharedComponent.modalLis.duplicateChecks.noDuplicatesFound"
                            )}
                        />
                        <ButtonWrapper>
                            <Button
                                name={message(
                                    "sharedComponent.modalLis.duplicateChecks.cancel"
                                )}
                                onClick={handleModalClose}
                            />
                            <Button
                                name={message(
                                    "sharedComponent.modalLis.duplicateChecks.createPlaceId"
                                )}
                                isLoading={isLoading}
                                disabled={
                                    !check ||
                                    userContextInfo?.role !== ROLES.SALES
                                }
                                onClick={updateCreatePlaceId}
                            />
                        </ButtonWrapper>
                    </FooterStyle>
                </FooterWraper>
            </BoxStyle>
        </>
    );
};

export default ModalList;
